import baseApi from '@/repositories/base_api';
import IPayout from '@/interfaces/payout/payout';
import { IAPIResponse } from '@/interfaces/api/response';
import { IPayoutForm } from '@/interfaces/payout/payout_form';


export const payoutApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPayouts: build.query<IPayout[], void>({
            query: () => ({ url: `/payouts`, method: 'get'}),
            transformResponse: (response: IAPIResponse<IPayout[]>) => response.data,
            keepUnusedDataFor: 1800
        }),
        createPayout: build.mutation<IPayout, IPayoutForm>({
            query: (data) => ({ url: `/payouts`, method: 'post', data }),
            transformResponse: (response: IAPIResponse<IPayout>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(payoutApi.util.updateQueryData('getPayouts', undefined, payouts => {
                    payouts.push(response.data)
                    payouts.sort((a, b) => b.requested_at.localeCompare(a.requested_at))
                }))).catch(() => {});
            },
            invalidatesTags: ['BusinessPerformance']
        }),
    }),
})

export const { 
    useGetPayoutsQuery,
    useCreatePayoutMutation
} = payoutApi;

