import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import IBusinessPerformance from '@/interfaces/business_performance/business_performance';


export const businessPerformanceApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPerformance: build.query<IBusinessPerformance, string>({
            query: (period) => ({ url: `/business-performance?period=${period}`, method: 'get'}),
            transformResponse: (response: IAPIResponse<IBusinessPerformance>) => response.data,
            keepUnusedDataFor: 1800,
            providesTags: ['BusinessPerformance']
        }),
    }),
})

export const { 
    useGetPerformanceQuery,
} = businessPerformanceApi;

