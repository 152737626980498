import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import { snakeToCapitalizedWords } from "@/_helpers/text_functions";

// Styles
import '@carbon/charts-react/styles.css';

// Components
import { Box } from "@mui/material";
import { ScaleTypes, GroupedBarChart } from '@carbon/charts-react';


interface _ChartObject {
    group: string;
    key: string;
    value: number;
}
interface _ChartData {
    date: string;
    [key: string]: any;
}

interface _BarChartProps<T extends _ChartData> {
    title?: string;
    data: T[];
    metric: string;
    yLabel?: string;
    xLabel?: string;
    yPrefix?: string;
}

const BarChart = <T extends _ChartData>({
    title,
    data,
    metric,
    yLabel,
    xLabel,
    yPrefix = ''
}: _BarChartProps<T>) => {

    const now = dayjs();

    const emptyData = [{ group: snakeToCapitalizedWords(metric), key: now, value: 0 }, { group: snakeToCapitalizedWords(metric), key: now.subtract(1, 'day'), value: 0 }]
      
    const chartData = useMemo(() => {
        const d: _ChartObject[] = [];
        data.map((item) => {
            const monthString = dayjs(item.date).format("MMM");
            d.push({
                group: item.group,
                key: monthString,
                value: item[metric],
            });
        });
    
        return d;
    }, [data, metric]);
    // Test data
    // chartData[11].value = 1000;
    // chartData[10].value = 2000;
    // chartData[22].value = 3000;
    // chartData[21].value = 7600;

    const noData = chartData.length == 0;

    // Calculate the min and max
    const maxValue = Math.max(...chartData.map(d => d.value));

    // Round down the min and round up the max to the nearest 10
    const roundedMax = Math.ceil(maxValue / 10) * 10;

    const colorScale = (): Record<string, string> => {
        const colors = [
            '#6929c4', // Primary purple
            '#1192e8', // Secondary blue
            '#005d5d', // Tertiary teal
        ];
        
        const colorScale: Record<string, string> = {};
        const groups = Array.from(
            new Set(chartData.map(item => item.group))
        ).sort();
        
        groups.map((group, index) => {
            colorScale[group] = colors[index];
        });
        
        return colorScale;
    };

    const barWidth = () => {
        const length = data.length;
        if (length == 1) return 50;
        if (length == 2) return 40;
        if (length >= 3 && length <= 5) return 30;
        if (length >= 6 && length <= 10) return 20;
        return 10;
    }

    const options = {
        title: '',
        axes: {
            bottom: {
                title: xLabel ?? '',
                mapsTo: 'key',
                scaleType: ScaleTypes.LABELS,
            },
            left: {
                title: yLabel ?? '',
                mapsTo: 'value',
                scaleType: ScaleTypes.LINEAR,
                domain: [0, roundedMax],
                ticks: {
                    formatter: (value: number) => {
                        if (value >= 1000) {
                            return `${yPrefix}${value / 1000}k`;
                        }
                        return `${yPrefix}${value}`;
                    }
                }
            }
        },
        bars: {
            width: barWidth()
        },
        curve: 'curveMonotoneX',
        toolbar: { enabled: false },
        legend: { enabled: true },
        grid: {
            x: { enabled: true },
            y: { enabled: true }
        },
        color: {
            scale: colorScale()
        },
        height: "90%",
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            width="90vw" height="50vh"
            alignItems="flex-start"
            padding="24px 32px" 
            boxSizing="border-box" 
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)" 
            sx={{bgcolor: 'var(--layer-01)'}}
            >
            {title && <span className="heading-07-compact" style={{marginBottom: '16px'}}>{title}</span>}
            <GroupedBarChart 
                data={!noData ? chartData : emptyData}
                options={options}
                />

            {noData && (
                <_NoData />
            )}
        </Box>
    )
}

export default BarChart;

const _NoData: FC = () => {

    const { t } = useTranslation();

    return (
        <Box 
            sx={{
                position: 'relative',
                bottom: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '22px',
                marginRight: '2px',
                opacity: 0.7,
                backgroundColor: 'var(--layer-01)', // Slightly opaque background
                backdropFilter: 'blur(4px)', // Apply blur effect to overlay
                zIndex: 2, // Ensure the overlay is on top of the chart
            }}>
            <span className="body-02" style={{color: 'var(--text-secondary)'}}>{t('components.charts.insufficientData')}</span>
        </Box>
    )
};