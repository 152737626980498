import { z } from "zod";
import { IFormSection } from "@/interfaces/form_builder/form_builder";
import ruleset from "@/_helpers/ruleset";
import { t } from "i18next";

export interface ICheckinSubmission  {
    id: string;
    client: ICheckinClient;
    client_questionnaire: IFormSection[];
    coach_feedback: IFeedback;
    status: string;
    created_at: string;
    submitted_at: string;
    reviewed_at: string;
    missed_at: string;
    readonly: boolean;
}

export interface ICheckinClient {
    id: number;
    name: string;
    avatar: string;
}

export interface IFeedback {
    comments: string;
    video_link: string;
}

export const feedbackFormSchema = z.object({
    comments: ruleset.required,
    video_link: z.custom((val) => {
        const isLoomValid = ruleset.loomLink.safeParse(val).success;
        const isScreencastValid = ruleset.screencastLink.safeParse(val).success;
        const isScreenPalValid = ruleset.screenPalLink.safeParse(val).success;
        const isSomupValid = ruleset.somupLink.safeParse(val).success;
        const isVimeoValid = ruleset.vimeoLink.safeParse(val).success;
        const isZoomValid = ruleset.zoomLink.safeParse(val).success;
        const isVideoLinkValid = ruleset.videolink.safeParse(val).success;

        if (!val || val == "") return true;

        if (isLoomValid || isScreencastValid || isVimeoValid || isZoomValid || isVideoLinkValid || isScreenPalValid || isSomupValid) {
            return true;
        }

        return false;
    }, { message: t('inputs.errors.checkinVideo') }).optional(),
});