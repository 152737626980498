import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod";

// Interfaces and helpers
import showToast from "@/_lib/toast";
import { ApiError } from "@/interfaces/api/error";
import { useFirstRender } from "@/_helpers/hooks";
import { ApiErrorEnum } from "@/_constants/api_errors";
import { ILoginCredentials, schema } from '@/interfaces/auth/login_credentials';

// Styles
import { East } from '@mui/icons-material';
import styles from '@/layouts/auth.module.scss';

// Services and selectors
import { selectIsAuthenticated } from "@/store/auth";
import { useLoginMutation } from '@/repositories/auth';

// Components
import { Box } from '@mui/material';
import Button from '@/components/button';
import EmailInput from '@/components/email_input';
import PasswordInput from '@/components/password_input';


const LoginPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isFirstRender = useFirstRender();

    const isAuthenticated = useSelector(selectIsAuthenticated)
    const [login, { isLoading }] = useLoginMutation();

    useEffect(() => {
        if (isAuthenticated) {
            console.log('isAuthenticated')
            navigate("/clients/active")
        }
    }, [isFirstRender, isAuthenticated, navigate]);

    const { control, handleSubmit, setError, formState } = useForm<ILoginCredentials>({
        resolver: zodResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const submitForm = async (data: ILoginCredentials) => {
        await login(data).unwrap().then(() => {
            navigate('/clients/active');
        }).catch((error: ApiError) => {
            if (error.type in ApiErrorEnum && error.type == 'auth_invalid_credentials') {
                setError('password', { type: 'api', message: t(`api.errors.${error.type}.message`)});
            } else if (error.type in ApiErrorEnum) {
                setError('email', { type: 'api', message: t(`api.errors.${error.type}.message`)});
            } else {
                showToast({type: 'error', apiError: error.type})
            }
        });
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            width="100%" 
            maxWidth="540px" 
            padding="48px 24px"
            boxSizing="border-box"
            textAlign="left"
            sx={{'@media (max-width: 768px)': {maxWidth: '100%'}}}
            >

            {/* title, subtitle and 40px spacer */}
            <span className="heading-05">{t('pages.login.title')}</span>
            <span className="body-01" style={{color: 'var(--text-secondary)'}}>{t('pages.login.subtitle')}</span>
            <Box height={40} />

            {/* Form */}
            <form onSubmit={handleSubmit(submitForm)} style={{width: '100%'}}>
                <EmailInput
                    name='email'
                    control={control}
                    label={t('inputs.titles.emailAddress')} 
                    placeHolder={t('inputs.placeholders.emailAddress')} 
                    tabIndex={1}
                    />
                <PasswordInput
                    name='password'
                    control={control}
                    label={t('inputs.titles.password')} 
                    placeHolder={t('inputs.placeholders.password')} 
                    tabIndex={2}
                    />
                <Box display="flex" justifyContent="end" width="100%">
                    <Link className="body-02" to={'/auth/forgot-password'}>{t('components.buttons.forgotPassword')}</Link>
                </Box>
                
                <Box height={40} />

                {/* Actions */}
                <Box className={styles.actions}>
                    <Box className={styles.actions__registration}>
                        <span className="body-02" style={{color: 'var(--text-secondary)'}}>{t('pages.login.noAccount')}</span>
                        <Link className="body-02" style={{color: 'var(--link-primary)'}} to={'/register/details'}>{t('components.buttons.signup')}</Link>
                    </Box>

                    <Box width={12} />

                    <Button
                        type="submit"
                        label={t('components.buttons.login')}
                        endIcon={<East />}
                        loading={isLoading}
                        disabled={isLoading || !formState.isValid}
                        onClick={handleSubmit(submitForm)}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                        />
                </Box>
            </form>
                
        </Box>
    )
}

export default LoginPage;

