import { t } from "i18next";
import { FC } from "react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import { ArrowUpRight } from "@carbon/icons-react";


interface _FeesModalProps {
    open: boolean;
    onClose: (() => void);
}

const FeesModal: FC<_FeesModalProps> = ({
    open,
    onClose,
}) => {

    const handleViewStripeFees = () => {
        window.open('https://stripe.com/pricing', '_blank');
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.processingFees.title')}
            text={t('modals.processingFees.text')}
            children={<_FeesContent />}
            action1={{
                kind: 'ghost',
                label: t('components.buttons.viewStripeFees'), 
                icon: <ArrowUpRight />,
                onClick: handleViewStripeFees}}
            sx={{'& .MuiPaper-root': {minWidth: '660px', background: 'var(--background)'}}}
            />
    )
}

export default FeesModal;

const _FeesContent: FC = () => {

    return (
        <Box display="flex" flexDirection="column">
            <span className="heading-07-compact">{t('modals.processingFees.content.platformFees')}</span>
            <Box height="16px" />
            <span className="body-02-compact" style={{color: 'var(--text-helper)'}}>{t('modals.processingFees.content.platformFeesText')}</span>
            <span className="body-02-compact" style={{paddingLeft: '8px', whiteSpace: 'pre-wrap', color: 'var(--text-helper)'}}>{t('modals.processingFees.content.platformFeesBullets')}</span>
            <Box height="16px" />
            <span className="heading-07-compact">{t('modals.processingFees.content.stripeFees')}</span>
            <Box height="16px" />
            <span className="body-02-compact" style={{color: 'var(--text-helper)'}}>{t('modals.processingFees.content.stripeFeesText')}</span>
        </Box>
    )
}