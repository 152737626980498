import { createSelector } from '@reduxjs/toolkit';

// Base API
import baseApi from '@/repositories/base_api';

// Interfaces
import { RootState } from '@/store/store';
import { IAPIResponse } from '@/interfaces/api/response';
import { IClientForm } from '@/interfaces/client/client_form';
import { IDataMetrics } from '@/interfaces/settings/data_metrics';
import IClient, { ICheckoutSession } from '@/interfaces/client/client';
import { IClientProductForm } from '@/interfaces/client/client_product';
import { IClientSettingsForm } from '@/interfaces/client/client_settings_form';
import { ITransferForm, ITransferManyForm } from '@/interfaces/transfer/transfer_form';


export const clientApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClients: build.query<IClient[], number|undefined>({
      query: (memberId) => ({ url: memberId ? `/clients?member=${memberId}` : 'clients', method: 'get' }),
      transformResponse: (response: IAPIResponse<IClient[]>) => response.data.sort((a, b) => a.full_name.localeCompare(b.full_name)),
      providesTags: [
        'Client.list',
        { type: 'Client', id: 'ALL' }
      ],
      keepUnusedDataFor: 1800
    }),
    getClient: build.query<IClient, number>({
      query: (id) => ({ url: `clients/${id}`, method: 'get' }),
      transformResponse: (response: IAPIResponse<IClient>) => response.data,
      providesTags: (result, error, id) => [{ type: 'Client', id }, {type: 'Client', id: 'ALL'}],
      keepUnusedDataFor: 1800
    }),
    addClient: build.mutation<IClient, IClientForm>({
      query: (data) => ({ url: 'clients', method: 'post', data: data }),
      transformResponse: (response: IAPIResponse<IClient>) => response.data,
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        const hasTeam = (getState() as RootState).team.id !== undefined;
        const memberId = (getState() as RootState).team.selectedMember?.id;
        queryFulfilled.then((response) =>
        dispatch(clientApi.util.updateQueryData('getClients', hasTeam ? memberId : undefined, clients => {
          clients.push(response.data)
          clients.sort((a, b) => a.full_name.localeCompare(b.full_name))
        }))).catch(() => {});
      },
    }),
    addClientProduct: build.mutation<ICheckoutSession, {id: number, data: IClientProductForm}>({
      query: ({id, data}) => ({ url: `clients/${id}/checkouts`, method: 'post', data: data }),
      transformResponse: (response: IAPIResponse<ICheckoutSession>) => response.data,
      async onQueryStarted({ id }, { dispatch, getState, queryFulfilled }) {
        const hasTeam = (getState() as RootState).team.id !== undefined;
        const memberId = (getState() as RootState).team.selectedMember?.id;
        queryFulfilled.then((response) => {
          dispatch(clientApi.util.updateQueryData('getClient', id, client => {
            client.checkout_session = response.data;
          }));
          dispatch(clientApi.util.updateQueryData('getClients', hasTeam ? memberId : undefined, clients => {
            const index = clients.findIndex((item) => item.id == id);
            if (index === -1) return;
            const client = clients[index];
            client.product = {
              product_name: response.data.product_name,
              status: response.data.status
            }
            clients.splice(index, 1, client);
          }));
        }).catch(() => {});
      },
      invalidatesTags: ['Storefront']
    }),
    updateClient: build.mutation<IClient, {id: number, data: IClientForm}>({
      query: ({id, data}) => ({ url: `clients/${id}`, method: 'post', data: data }),
      transformResponse: (response: IAPIResponse<IClient>) => response.data,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(clientApi.util.upsertQueryData('getClient', id, response.data)).catch(() => {}));
      },
      invalidatesTags: ['Transactions']
    }),
    updateClientSettings: build.mutation<IDataMetrics, {id: number, data: IClientSettingsForm}>({
      query: ({id, data}) => ({ url: `clients/${id}/settings`, method: 'post', data: data }),
      transformResponse: (response: IAPIResponse<IDataMetrics>) => response.data,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(clientApi.util.updateQueryData('getClient', id, client => {
            client.coach_settings = response.data;
        }))).catch(() => {});
      },
    }),
    updateClientStatus: build.mutation<IClient, number>({
      query: (id) => ({ url: `clients/${id}/status`, method: 'patch' }),
      transformResponse: (response: IAPIResponse<IClient>) => response.data,
      async onQueryStarted(id, { dispatch, getState, queryFulfilled }) {
        const memberId = (getState() as RootState).team.selectedMember?.id;
        queryFulfilled.then((response) => {
        dispatch(baseApi.util.updateQueryData('getClients' as never, memberId as never, (clients: IClient[]) => {
            const index = clients.findIndex((item) => item.id === response.data.id);
            clients.splice(index, 1, response.data);
        }));
        dispatch(baseApi.util.invalidateTags([
          { type: 'Client', id }, 
          { type: 'Plans.programme.list', id },
          { type: 'Plans.nutrition.list', id },
          { type: 'Plans.activity.list', id },
          { type: 'Plans.supplement.list', id },
        ]));
      }).catch(() => {});
      },
    }),
    onboardClient: build.mutation<IClient, number>({
      query: (id) => ({ url: `clients/${id}/onboard`, method: 'post' }),
      transformResponse: (response: IAPIResponse<IClient>) => response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(clientApi.util.upsertQueryData('getClient', arg, response.data))).catch(() => {});
      },
    }),
    cancelClientCheckout: build.mutation<void, {id: number, session_id: string}>({
      query: ({id, session_id}) => ({ url: `clients/${id}/checkouts/${session_id}`, method: 'delete' }),
      transformResponse: (response: IAPIResponse<void>) => response.data,
      async onQueryStarted({ id }, { dispatch, getState, queryFulfilled }) {
        const hasTeam = (getState() as RootState).team.id !== undefined;
        const memberId = (getState() as RootState).team.selectedMember?.id;
        queryFulfilled.then(() => {
          dispatch(clientApi.util.updateQueryData('getClient', id, client => {
            client.checkout_session = undefined;
            return client;
          }));
          dispatch(clientApi.util.updateQueryData('getClients', hasTeam ? memberId : undefined, clients => {
            const index = clients.findIndex((item) => item.id == id);
            if (index === -1) return;
            const client = clients[index];
            client.product = undefined;
            clients.splice(index, 1, client);
          }));
        }).catch(() => {});
      },
      invalidatesTags: ['Storefront']
    }),
    deleteClient: build.mutation<void, number>({
      query: (id) => ({ url: `/clients/${id}`, method: 'delete' }),
      transformResponse: (response: IAPIResponse<void>) => response.data,
      async onQueryStarted(id, { dispatch, getState, queryFulfilled }) {
        const memberId = (getState() as RootState).team.selectedMember?.id;
        queryFulfilled.then(() =>
        dispatch(clientApi.util.updateQueryData('getClients', memberId, (clients: IClient[]) => {
            return clients.filter((item) => item.id !== id)
        }))).catch(() => {});
      },
    }),
    deleteClients: build.mutation<void, number[]>({
        query: (ids) => ({ url: `/clients/bulk-delete`, method: 'post', data: {ids: ids} }),
        transformResponse: (response: IAPIResponse<void>) => response.data,
        async onQueryStarted(ids, { dispatch, getState, queryFulfilled }) {
          const memberId = (getState() as RootState).team.selectedMember?.id;
          queryFulfilled.then(() =>
          dispatch(clientApi.util.updateQueryData('getClients', memberId, (clients: IClient[]) => {
              return clients.filter((item) => !ids.includes(item.id))
          }))).catch(() => {});
        },
    }),
    transferClient: build.mutation<void, ITransferForm>({
        query: (data) => ({ url: `/clients/${data.id}/transfer`, method: 'patch', data: data }),
        transformResponse: (response: IAPIResponse<void>) => response.data,
        invalidatesTags: (result, error, { id }) => [
          { type: 'Client', id: 'ALL' },
          { type: 'Plans.programme.list', id },
          { type: 'Plans.nutrition.list', id },
          { type: 'Plans.activity.list', id },
          { type: 'Plans.supplement.list', id }, 
          'Checkin.submission.client.list'
      ],
    }),
    transferClients: build.mutation<void, ITransferManyForm>({
        query: (data) => ({ url: `/clients/bulk-transfer`, method: 'patch', data: data }),
        transformResponse: (response: IAPIResponse<void>) => response.data,
        invalidatesTags: ['Clients.list'],
    }),
  }),
})

export const { 
  useGetClientsQuery, 
  useGetClientQuery, 
  useAddClientMutation,
  useAddClientProductMutation,
  useUpdateClientMutation,
  useUpdateClientSettingsMutation,
  useUpdateClientStatusMutation,
  useOnboardClientMutation,
  useCancelClientCheckoutMutation,
  useDeleteClientMutation,
  useDeleteClientsMutation,
  useTransferClientMutation,
  useTransferClientsMutation
} = clientApi;

export const selectIsClientReadonly = createSelector([state => state, (_, params) => params], (state, params) =>
  clientApi.endpoints.getClient.select(params)(state)?.data?.readonly ?? false)

export const selectClientMetrics = createSelector([state => state, (_, params) => params], (state, params) =>
  clientApi.endpoints.getClient.select(params)(state)?.data?.coach_settings?.training)
