import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Toast
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { ApiError } from "@/interfaces/api/error";
import { selectIsMember, selectIsOwner } from "@/store/team";
import { ITeamMemberForm } from "@/interfaces/team/member_form";
import { ITeamInvitation, ITeamMember } from "@/interfaces/team/team";
import { useSuspendTeamMemberMutation, useUpdateTeamMemberMutation } from "@/repositories/team_member";

// Components
import TeamMemberModal from "./member_modal";
import CancelInviteModal from "./cancel_modal";
import ResendInviteModal from "./resend_modal";
import DeleteTeamMemberModal from "./delete_modal";
import SuspendTeamMemberModal from "./suspend_modal";
import OverflowMenu from "@/components/overflow_menu";


interface TeamMenuProps {
    row: ITeamMember | ITeamInvitation;
}

const TeamMenu: FC<TeamMenuProps> = ({
    row
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const coachId = useSelector(selectCoachId);
    const isMember = useSelector(selectIsMember);
    const isOwner = useSelector(selectIsOwner);
    const [updateTeamMember, { isLoading: isUpdating }] = useUpdateTeamMemberMutation();
    const [suspendTeamMember, { isLoading: isSuspending }] = useSuspendTeamMemberMutation();
    const disableMenu: boolean = row.id === coachId || isMember;
      
    function isInvite(item: any): item is ITeamMember {
        return (item as ITeamMember).total_clients == undefined;
    }

    const handleUpdateMember = (data: ITeamMemberForm) => {
        updateTeamMember({id: row.id, data: data}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.team.memberUpdated.title'), message: t('notifications.team.memberUpdated.message')});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const handleSuspendMember = () => {
        suspendTeamMember(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.team.memberSuspended.title', {context: `${row.status == 'active'}`}), 
                message: t('notifications.team.memberSuspended.message', {context: `${row.status == 'active'}`, name: row.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const options = isInvite(row) ? [
        {name: t('components.menuItems.resendInvite'), action: () => setOpen('resend')}
    ] :
    [
        {name: t('components.menuItems.edit'), action: () => setOpen('edit')},
        {name: t(`components.menuItems.${row.status == 'active' ? 'suspend' : 'reinstate'}`), disabled: !isOwner, action: () => setOpen('suspend')},
    ]

    return (
        <>
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={isInvite(row) ? t('components.menuItems.cancelInvite') : t('components.menuItems.delete')}
                disabled={disableMenu}
                disableDelete={!isOwner}
                onDelete={() => setOpen(isInvite(row) ? 'cancel' : 'delete' )}
                />
                {open == 'edit' && <TeamMemberModal 
                    open={open === 'edit'} 
                    onClose={() => setOpen(null)} 
                    member={row as ITeamMember} 
                    isLoading={isUpdating} 
                    onSubmit={handleUpdateMember}
                    />}
                {open == 'suspend' && <SuspendTeamMemberModal 
                    open={open === 'suspend'} 
                    onClose={() => setOpen(null)} 
                    member={row as ITeamMember} 
                    isLoading={isSuspending} 
                    onSubmit={handleSuspendMember}
                    />}
                {open == 'delete' && <DeleteTeamMemberModal 
                    open={open === 'delete'} 
                    onClose={() => setOpen(null)} 
                    member={row as ITeamMember} 
                    />}
                {open == 'resend' && <ResendInviteModal 
                    open={open === 'resend'} 
                    onClose={() => setOpen(null)} 
                    invitation={row as ITeamInvitation} 
                    />}
                {open == 'cancel' && <CancelInviteModal 
                    open={open === 'cancel'} 
                    onClose={() => setOpen(null)} 
                    invitation={row as ITeamInvitation} 
                    />}
        </>
    )
}

export default TeamMenu;