
import { teamApi } from "@/repositories/team";
import { authApi } from "@/repositories/auth";
import { teamMemberApi } from "@/repositories/team_member";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITeamResponse } from "@/interfaces/team/team_response";
import { teamInvitationApi } from "@/repositories/team_invitation";
import { ITeamInvitation, ITeamMember, ITeamSettings, TeamRole } from "@/interfaces/team/team";


interface IState {
  loaded: boolean;
  id: number|undefined;
  name: string|undefined;
  role: TeamRole|undefined;
  settings: ITeamSettings|undefined;
  members: ITeamMember[]|undefined;
  invitations: ITeamInvitation[]|undefined;
  invitation: ITeamInvitation|undefined;
  selectedMember: ITeamMember|undefined;
}

const initialState: IState = {
  loaded: false,
  id: undefined,
  name: undefined,
  role: undefined,
  settings: undefined,
  members: [],
  invitations: [],
  invitation: undefined,
  selectedMember: undefined,
};


export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setSelectedMember: (state, action: PayloadAction<number>) => {
      state.selectedMember = state.members?.find(member => member.id === action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(teamApi.endpoints.getTeam.matchFulfilled, (state, { payload }) => {
      if (!payload) return;
      updateState(state, payload);
    }).addMatcher(teamApi.endpoints.createTeam.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(teamApi.endpoints.updateTeam.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(teamApi.endpoints.deleteTeam.matchFulfilled, (state) => {
      resetState(state);
    }).addMatcher(teamMemberApi.endpoints.updateTeamMember.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(teamMemberApi.endpoints.suspendTeamMember.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(teamMemberApi.endpoints.deleteTeamMember.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
      state.selectedMember = undefined;
    }).addMatcher(teamInvitationApi.endpoints.createTeamInvitation.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(teamInvitationApi.endpoints.cancelTeamInvitation.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(teamInvitationApi.endpoints.declineInvitation.matchFulfilled, (state) => {
      state.invitation = undefined;
    }).addMatcher(teamInvitationApi.endpoints.acceptInvitation.matchFulfilled, (state, { payload }) => {
      updateState(state, payload);
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    })
  },
  selectors: {
    selectTeamHydrated: (state): boolean => state.loaded,
    selectTeam: (state): IState => state,
    selectHasTeam: (state): boolean => state.id !== undefined,
    selectHasInvitation: (state): boolean => state.invitation !== undefined,
    selectHasTeamOrInvitation: (state): boolean => state.id !== undefined || state.invitation !== undefined,
    selectInvitation: (state): ITeamInvitation|undefined => state.invitation,
    selectIsOwner: (state): boolean => state.role === 'owner',
    selectIsMember: (state): boolean => state.role === 'member',
    selectMembers: (state): ITeamMember[] => state.members ?? [],
    selectSelectedMember: (state): ITeamMember|undefined => state.selectedMember
  }
});

const updateState = (state: IState, payload: ITeamResponse) => {
  state.id = payload.team?.id;
  state.name = payload.team?.name;
  state.role = payload.role;
  state.settings = payload.settings;
  state.members = payload.team?.members;
  state.invitations = payload.team?.invitations;
  state.invitation = payload.invitation;
}

const resetState = (state: IState) => {
  state.id = undefined;
  state.name = undefined;
  state.role = undefined;
  state.settings = undefined;
  state.members = [];
  state.invitations = [];
  state.invitation = undefined;
  state.selectedMember = undefined;
}

export const { 
  selectTeamHydrated,
  selectTeam, 
  selectHasTeam,
  selectHasInvitation, 
  selectHasTeamOrInvitation,
  selectInvitation,
  selectIsOwner, 
  selectIsMember,
  selectMembers,
  selectSelectedMember
} = teamSlice.selectors;

export const { setSelectedMember } = teamSlice.actions;

export default teamSlice.reducer;
