import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue, transformEmptyStringToNull } from "@/_helpers/ruleset";

export interface IProspectConvertForm {
    payment_amount: number|string,
    payment_schedule: string,
    payment_product: number|string,
    payment_start: string,
    checkin_template_id: string,
    checkin_days: number|number[],
    checkin_frequency: string
}

export const prospectConvertSchema = z.object({
    payment_amount: transformEmptyStringToNull(ruleset.numberOptional),
    payment_schedule: ruleset.selectOptional,
    payment_product: transformEmptyStringToNull(ruleset.numberOptional),
    payment_start: transformEmptyStringToNull(ruleset.dateOptional),
    checkin_template_id: ruleset.required,
    checkin_days: z.preprocess(
        (val) => (Array.isArray(val) ? val : [val]),
        ruleset.checkinDays
    ),
    checkin_frequency: ruleset.required,
}).superRefine((data, context) => {
    if (data.checkin_frequency == 'weekly') {
        if (!data.checkin_days || data.checkin_days.length === 0) {
            addIssue(context, ['checkin_days'], t('inputs.errors.checkinDays'));
        }
    } else if (data.checkin_frequency == 'biweekly') {
        if (!data.checkin_days || data.checkin_days.length === 0) {
            addIssue(context, ['checkin_days'], t('inputs.errors.checkinDay'));
        }
    }
});