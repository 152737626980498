import { useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { IProduct } from "@/interfaces/product/product";

// Components
import Tag from "@/components/tag";
import ProductMenu from "./product_menu";
import { ColDef } from "@/components/datatable";
import { CircularProgress } from "@mui/material";


const useProductTableHeaders = (): ColDef<IProduct>[] => {

    const { t } = useTranslation();
    const [restoring, setRestoring] = useState<number|null>(null);

    const productStatus = (product: IProduct): string => {
        if (product.default) return 'default';
        return product.active ? 'active' : 'archived';
    }

    const tagColor = (product: IProduct) => {
        if (product.default) return 'blue';
        return product.active ? 'green' : 'red';
    }

    const headers: ColDef<IProduct>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row) => capitalizeWords(row.name)
        },
        {
            field: 'price',
            headerName: t('components.dataTable.headers.price'),
            width: '10%',
            // sortable: true,
            render: (row) => row.price ? `${row.price.unit_amount.toFixed(2)} ${row.currency.toUpperCase()}` : '-'
        },
        {
            field: 'subtype',
            headerName: t('components.dataTable.headers.billingCycle'),
            width: '10%',
            // sortable: true,
            render: (row) => row.subtype ? t(`enums.productSubtype.${row.subtype}`) : '-'
        },
        // {
        //     field: 'duration',
        //     headerName: t('components.dataTable.headers.duration'),
        //     width: '15%',
        //     sortable: true,
        //     //TODO translation missing
        //     render: (row) => row.schedule && row.schedule.end_behaviour == 'release' ? 'Until Cancelled' :
        //         row.schedule && row.schedule.end_behaviour == 'cancel' ? `22 weeks` : '-'
        // },
        {
            field: 'active',
            headerName: t('components.dataTable.headers.status'),
            width: '10%',
            sortable: true,
            render: (row) => restoring && restoring == row.id ? 
                <CircularProgress size={16} sx={{marginLeft: '18px'}} /> : 
                <Tag label={t(`components.tag.labels.${productStatus(row)}`)} colour={tagColor(row)} />
        },
        {
            field: 'total_clients',
            headerName: t('components.dataTable.headers.clients'),
            width: '10%',
            render: (row: IProduct) => row.total_clients ? `${row.total_clients}` : '-'
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IProduct) => <ProductMenu 
                row={row}
                onRestore={(v) => setRestoring(v)} 
                />
        }
    ];

    return headers;
}

export default useProductTableHeaders;