import dayjs, { Dayjs } from "dayjs";
import { Trans } from "react-i18next";
import { FC, useRef, useState } from "react";
import { Control, useController } from "react-hook-form";

// Helpers
import { formatDateAsText } from "@/_helpers/date_functions";

// Styles
import { Calendar as CalenderIcon, Help } from "@carbon/icons-react";

// Components
import Tooltip from "./tooltip";
import { Error } from '@mui/icons-material';
import Calendar, { AvailableDates } from "./calendar";
import { TextField, InputAdornment, Box, Menu } from "@mui/material";


export interface DateInputProps {
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  // Takes the key of the tooltip from the i18n file
  tooltip?: string;
  tabIndex?: number;
  forceUtc?: boolean;
  toLocal?: boolean;
  availableDates?: AvailableDates
  useDayNomenclature?: boolean;
  disabled?: boolean;
}

const DateInput: FC<DateInputProps>  = ({
  name,
  control,
  size = 'small',
  label,
  tooltip,
  tabIndex = -1,
  forceUtc = true,
  toLocal = false,
  availableDates,
  useDayNomenclature = false,
  disabled = false
}) => {

  const { field, fieldState } = useController({name, control});

  const ref = useRef();
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    if (disabled) return;
    setOpen(!open);
  }

  const handleChange = (date: Dayjs) => {
    setOpen(false)
    if (toLocal) {
      return field.onChange(forceUtc ? dayjs.utc(date.format('YYYY-MM-DDTHH:mm:ss')).format('YYYY-MM-DDTHH:mm:ss') : date.format('YYYY-MM-DDTHH:mm:ss'))
    }
    field.onChange(forceUtc ? dayjs.utc(date.format('YYYY-MM-DDTHH:mm:ss')).toISOString() : date.toISOString())
  }

  const classes = {
    'TextInput': true,
    'TextInput-small': size == 'small',
    'TextInput-medium': size == 'medium',
    'TextInput-large': size == 'large'
  };

  const validClasses = Object.entries(classes)
    .filter(([,v]) => !!v)
    .map(([k,]) => k)
    .join(' ')

  return (
    // Width set to 100% to always fill what ever container it is in
    <Box ref={ref} className='input__container' sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '12px', width: '100%'}}>
      
       <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        {label && <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>}
        {/* Tooltip */}
        
        {tooltip && <Tooltip kind="nav" size="medium" title={<Trans className="body-01" i18nKey={tooltip}></Trans>} placement="top">
            <Help />
        </Tooltip>}
      </Box>

      <TextField
        className={validClasses}
        inputProps={{
          ...field,
          value: useDayNomenclature ? formatDateAsText(field.value) : dayjs.utc(field.value).format('DD/MM/YYYY'),
          readOnly: true
        }}
        placeholder='dd/mm/yyyy'
        tabIndex={tabIndex}
        error={fieldState.invalid}
        onClick={toggleOpen}
        helperText={fieldState.error?.message}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ml: 0}}>
              {fieldState.invalid && (
                <Error color="error" sx={{
                  fontSize: '16px', marginRight: '4px'
                }}/>
              )}
              <CalenderIcon />
            </InputAdornment>
          )
        }}
      />
      <Menu 
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
        sx={{zIndex: 10000}}
        >
        <Box p={1}>
          <Calendar
            value={dayjs(field.value.substring(0,10))}
            availableDates={availableDates}
            onChange={handleChange}
          />
        </Box>
      </Menu>

    </Box>
  );
};

export default DateInput;