import { z } from 'zod';
import i18next from 'i18next';
import ruleset from '@/_helpers/ruleset';

export interface IRegistrationForm {
    [key: string]: string | boolean;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    access_code: string;
    password: string;
    password_confirmation: string;
    business_name: string;
    terms_accepted: boolean;
}

export const registrationSchema = z.object({
    email: ruleset.email,
    phone_number: ruleset.phoneNumber,
    first_name: ruleset.name,
    last_name: ruleset.name,
    access_code: ruleset.accessCode,
    password: ruleset.passwordCriteria,
    password_confirmation: ruleset.passwordConfirmation,
    terms_accepted: ruleset.checkbox
}).superRefine(({ password, password_confirmation }, ctx) => {
    if (password !== password_confirmation) {
        ctx.addIssue({
        code: "custom",
        message: i18next.t('inputs.errors.passwordMismatch'),
        path: ['password_confirmation']
        });
    }
});

export const detailsSchema = z.object({
    email: ruleset.email,
    phone_number: ruleset.phoneNumber,
    first_name: ruleset.name,
    last_name: ruleset.name,
    access_code: ruleset.accessCode,
});

export const passwordSchema = z.object({
    password: ruleset.passwordCriteria,
    password_confirmation: ruleset.passwordConfirmation,
}).superRefine(({ password, password_confirmation }, ctx) => {
    if (password !== password_confirmation) {
        ctx.addIssue({
        code: "custom",
        message: i18next.t('inputs.errors.passwordMismatch'),
        path: ['password_confirmation']
        });
    }
});

export const businessSchema = z.object({
    business_name: ruleset.name,
    terms_accepted: ruleset.checkbox
});