import baseApi from '@/repositories/base_api';

// Interfaces
import { clientApi } from './client';
import IClient from '@/interfaces/client/client';
import { IAPIResponse } from '@/interfaces/api/response';
import { IClientSubscriptionChangeForm, IClientSubscriptionEndForm } from '@/interfaces/client_subscription/client_subscription_form';


export const clientSubscriptionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        //REVIEW sholud the end point be by sub id?
        changeClientSubscription: build.mutation<IClient, {client_id: number, data: IClientSubscriptionChangeForm}>({
            query: ({client_id, data}) => ({ url: `/clients/${client_id}/subscriptions/change`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                    dispatch(clientApi.util.upsertQueryData('getClient', client_id, response.data)).catch(() => {}));
            },
            invalidatesTags: ['Client.list', 'Transactions']
        }),
        cancelPendingProductChange: build.mutation<IClient, number>({
            query: (client_id) => ({ url: `/clients/${client_id}/subscriptions/revert`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted(client_id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                    dispatch(clientApi.util.upsertQueryData('getClient', client_id, response.data)).catch(() => {}));
            },
            invalidatesTags: ['Client.list']
        }),
        cancelClientSubscription: build.mutation<IClient, {client_id: number, data: IClientSubscriptionEndForm}>({
            query: ({client_id, data}) => ({ url: `/clients/${(client_id)}/subscriptions/cancel`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                    dispatch(clientApi.util.upsertQueryData('getClient', client_id, response.data)).catch(() => {}));
            },
            // TODO when teams is implemented, this should change to update the cache instead to be more efficient
            invalidatesTags: ['Storefront', 'Client.list']
        }),
        renewClientSubscription: build.mutation<IClient, number>({
            query: (client_id) => ({ url: `/clients/${(client_id)}/subscriptions/renew`, method: 'post' }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted(client_id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                    dispatch(clientApi.util.upsertQueryData('getClient', client_id, response.data)).catch(() => {}));
            },
            invalidatesTags: ['Storefront', 'Client.list']
        }),
    }),
})

export const { 
    useChangeClientSubscriptionMutation,
    useCancelPendingProductChangeMutation,
    useCancelClientSubscriptionMutation,
    useRenewClientSubscriptionMutation,
} = clientSubscriptionApi;

