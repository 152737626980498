import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";


export interface IPayoutForm {
    type: 'partial' | 'full';
    amount?: number|string;
}

export const payoutFormSchema = (available_balance: number, currency: string) => {

    return z.object({
        type: ruleset.select,
        amount: ruleset.numberOptional,
    }).superRefine((data, context) => {
        if (!data.amount || data.amount === 0) 
            addIssue(context, ['amount'], t('inputs.errors.refundNotZero', {currency: `${currency}`}));
        if (data.type === 'partial' && data.amount && data.amount > available_balance) 
            addIssue(context, ['amount'], t('inputs.errors.refundGreater', {amount: `${currency}${available_balance}`}));
    })

}
