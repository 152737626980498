import baseApi from '@/repositories/base_api';

// Interfaces
import { IAPIResponse } from '@/interfaces/api/response';
import { ITeamResponse } from '@/interfaces/team/team_response'
import { ITeamMemberForm } from '@/interfaces/team/member_form';


export const teamInvitationApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        createTeamInvitation: build.mutation<ITeamResponse, ITeamMemberForm>({
            query: (data) => ({ url: '/team/invitation', method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
        }),
        resendTeamInvitation: build.mutation<void, number>({
            query: (id) => ({ url: `/team/invitation/${id}/resend`, method: 'post'}),
        }),
        cancelTeamInvitation: build.mutation<ITeamResponse, number>({
            query: (id) => ({ url: `/team/invitation/${id}`, method: 'delete'}),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
        }),
        acceptInvitation: build.mutation<ITeamResponse, void>({
            query: () => ({ url: '/team/invitation/accept', method: 'post' }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
            invalidatesTags: ['Subscription', 'Storefront']
        }),
        declineInvitation: build.mutation<any, void>({
            query: () => ({ url: '/team/invitation/decline', method: 'post' }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
        }),
    }),
})

export const { 
    useCreateTeamInvitationMutation, 
    useResendTeamInvitationMutation, 
    useCancelTeamInvitationMutation,
    useAcceptInvitationMutation, 
    useDeclineInvitationMutation
} = teamInvitationApi;

