import { FC } from "react";
import { t } from "i18next";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";


interface _HelpModalProps {
    open: boolean;
    onClose: () => void;
}
const HelpModal: FC<_HelpModalProps> = ({
    open,
    onClose,
}) => {

    const videoUrl = 'https://www.youtube.com/watch?v=IO6ZCHebVXo';
    const videoId = videoUrl.split('v=')[1] || videoUrl.split('youtu.be/')[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.learnMoreAboutProducts.title')}
            text={t('modals.learnMoreAboutProducts.text')}
            children={
                <Box display="flex" marginBottom="12px" width='100%'>
                    <iframe width='100%' style={{aspectRatio: '16/9'}} src={embedUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Box>
            }
            sx={{'& .MuiPaper-root': {maxWidth: '950px'}}}
            />
    )
}

export default HelpModal;