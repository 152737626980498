import dayjs, { Dayjs } from 'dayjs';
import ReactCalendar from 'react-calendar';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

// Styles
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';

// Components
import Tag from '@/components/tag';
import Box from "@mui/material/Box";
import IconButton from '@/components/icon_button';


const dayNames = ["S", "M", "T", "W", "T", "F", "S"];

export interface AvailableDates {
    start: Dayjs;
    end: Dayjs;
}

interface HighlightRange {
    key: string;
    title: string;
    kind?: 'secondary' | 'active';
    range: [Dayjs, Dayjs];
}

interface CalendarProps {
    value?: Dayjs;
    range?: HighlightRange[];
    // Optional prop to limit the available dates
    availableDates?: AvailableDates;
    hideMonthSelect?: boolean;
    hideToday?: boolean;
    // Optional prop to override the disabled tile class
    overrideDisabledTile?: boolean;
    onChange?: (date: Dayjs) => void;
}

const Calendar: FC<CalendarProps> = ({
    value = dayjs(),
    range,
    availableDates,
    hideMonthSelect = false,
    hideToday = false,
    overrideDisabledTile = false,
    onChange,
}) => {
    
    const classes = {
        'hide-today': hideToday,
    }

    const tileClasses = overrideDisabledTile ? 'react-calendar__tile--override-disabled' : '';
    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const [date, setDate] = useState<Dayjs>(value)
    const calendarRef = useRef<HTMLDivElement|null>(null)

    const filteredRanges = useMemo(() => {
        return range?.filter(r => date.isBetween(r.range[0], r.range[1], 'month', '[]'))
    }, [range, date])

    // cunting workaround for injecting date ranges into the calendar
    useEffect(() => {
        const days = calendarRef?.current?.querySelectorAll('button.react-calendar__tile') 
        // clear initial classes before start
        days?.forEach(d => d.classList.remove('date-range', 'active', 'secondary', 'first', 'last'))
        filteredRanges?.sort((a,b) => b.kind?.localeCompare(a?.kind ?? 'secondary') ?? 1)?.forEach(r => {
            days?.forEach(d => {
                const date = dayjs((d.firstChild as HTMLElement)?.ariaLabel)
                if (date.isBetween(r.range[0], r.range[1], "day", '[]')) {
                    // clean up day css
                    d.classList.remove('date-range', 'active', 'secondary', 'first', 'last') 
                    d.classList.add('date-range', r.kind ?? 'secondary')
                }

                if (date.isSame(r.range[0], 'day')) d.classList.add('first')
                if (date.isSame(r.range[1], 'day')) d.classList.add('last')
            }) // end day
        }) // end range
    }, [filteredRanges, calendarRef.current]);

    const disableDate = (date: Date): boolean => {
        if (!onChange) return true;
        
        // If the date is not in the available dates, disable it
        const d = dayjs.utc(date);
        if (availableDates && !d.isBetween(availableDates.start, availableDates.end, null, '[]')) return true;
        return false;
    }

    return (
        <Box>
            {!hideMonthSelect && (
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                <IconButton 
                    kind='ghost'
                    size='small'
                    icon={<ChevronLeft />}
                    onClick={() => setDate(date.subtract(1, 'month'))}
                />
                <Box px={1} className='heading-07-compact text-primary'>
                    {date.format('MMMM YYYY')}
                </Box>
                <IconButton 
                    kind='ghost'
                    size='small'
                    icon={<ChevronRight />}
                    onClick={() => setDate(date.add(1, 'month'))}
                />
            </Box>
            )}

            {/* calendar */}
            <ReactCalendar 
                className={validClasses}
                inputRef={calendarRef}
                defaultValue={date.toDate()}
                tileClassName={tileClasses}
                tileDisabled={({date}) => disableDate(date)}
                formatShortWeekday={(_, d) => dayNames[d.getDay()]}
                onChange={(val,) => onChange?.(dayjs(val?.toString()))}
                />

            {/* highlight with names */}
            <Box mt={1} sx={{'& > .MuiBox-root': { width: '100%' }}}>
                {filteredRanges?.map(r => (
                    <Tag
                        key={r.key}
                        label={r.title} 
                        sx={{width: '100%'}}
                        colour={r.kind == 'active' ? 'teal' : 'grey'}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default Calendar;
export type { HighlightRange }