import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

// Helpers
import { useMobile } from "@/_lib/is_mobile";

// Config
import useNavigationItems from "@/_configs/navigation";

// Services and interfaces
import { selectPastDue } from "@/store/subscription";
import { selectHasInvitation, selectInvitation } from "@/store/team";
import { IRequirementBanner } from "@/interfaces/storefront/storefront";
import { selectStorefrontRequirements } from "@/repositories/storefront";

// Styles
import styles from './default.module.scss'
import { SidePanelOpen, SidePanelClose } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";

import Tooltip from "@/components/tooltip";
import { Banner } from "@/components/banner";
import ListItemButton from "@mui/material/ListItemButton";
import NavButton from "@/components/navigation/nav_button";
import NavSideMenu from "@/components/navigation/side_menu";
import NavToolbar from "@/components/navigation/nav_toolbar";
import AppUpdatedModal from "@/components/app_updated_modal";


export default function DefaultLayout() {

    const isMobile = useMobile();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navItems = useNavigationItems();
    const [listExpanded, setListExpanded] = useState<string|null>(null);
    const storefrontRequirements = useSelector((state) => selectStorefrontRequirements(state, undefined));

    const [open, setOpen] = useState(() => {
        const storedValue = localStorage.getItem('navOpen');
        if (storedValue === null) return true;
        return storedValue === 'true';
    });
    
    const toggleForceOpen = () => setOpen(!open);
    const drawerWidth = open ? 256 : 56;

    useEffect(() => {
        localStorage.setItem('navOpen', String(open));
    }, [open]);

    const subscriptionIsPastDue = useSelector(selectPastDue);

    const hasTeamInvite = useSelector(selectHasInvitation);
    const teamInvitation = useSelector(selectInvitation);

    const showRequirementBanner = (): IRequirementBanner|null => {
        if (!storefrontRequirements) return null;
        for (const [key, value] of Object.entries(storefrontRequirements)) {
            if (key === "errors" || key === "current_deadline") continue;
            if (value === "past_due") {
                return { requirement: key, status: value };
            } else if (value === "currently_due") {
                return { requirement: key, status: value };
            }
        }
        return null;
    };

    // set user context
    Sentry.setContext('device', {
        'screen_resolution': `${window.screen.availWidth}x${window.screen.availHeight}`,
        'screen_height_pixels': window.screen.availHeight,
        'screen_width_pixels': window.screen.availWidth
    });

    return (
        <>
            <NavToolbar />
            <Drawer
                variant="permanent"
                className={styles.navdrawer}
                PaperProps={{sx: (theme) => ({
                    zIndex: theme.zIndex.drawer +1,
                    bgcolor: 'var(--nav-background)',
                    boxSizing: 'border-box',
                    width: drawerWidth,
                    overflow: 'hidden',
                    transition: 'width 0.3s ease-in'
                })}}
                sx={{'@media (max-width: 768px)': {display: 'none'}}}
                >
                <Toolbar variant='dense' />
                <Box overflow='hidden'>
                    <List dense sx={{py: 1, px: 0.5}}>
                    {navItems.map(i => ( 
                        <Box key={i.path}>
                            {!open ? <Tooltip kind="nav" size='medium' placement='right' key={i.path} title={i.label}>
                                <Box>
                                    <NavButton
                                        label={i.label} 
                                        path={i.path} 
                                        replace={i.replace}
                                        startIcon={i.startIcon} 
                                        tabsRoot={i.tabsRoot}
                                        hasSubMenu={i.children !== undefined} 
                                        isOpen={listExpanded === i.label} 
                                        onClick={() => setListExpanded(listExpanded !== i.label && i.children ? i.label : null)}
                                        marginBottom="8px"
                                    />
                                </Box>
                            </Tooltip> :
                            <NavButton
                                label={i.label} 
                                path={i.path}
                                replace={i.replace}
                                startIcon={i.startIcon} 
                                tabsRoot={i.tabsRoot}
                                hasSubMenu={i.children !== undefined} 
                                isOpen={listExpanded === i.label} 
                                onClick={() => setListExpanded(listExpanded !== i.label && i.children ? i.label : null)}
                                marginBottom="8px"
                            />}
                            {/* sub nav items */}
                            {i.children && 
                                <Collapse in={listExpanded === i.label && open}>
                                    <List dense sx={{ml:2, py: 0}}>
                                    {i.children.map(c => (
                                        // TODO this needs fixing
                                        <NavButton 
                                            key={c.path} 
                                            label={c.label} 
                                            path={c.path}
                                            replace={c.replace}
                                            startIcon={c.startIcon}
                                            tabsRoot={c.tabsRoot}
                                            kind="submenu" marginBottom="8px"
                                        />
                                    ))}
                                    </List>
                                </Collapse>
                            }
                        </Box>
                    ))}
                    </List>
                </Box>
                <Box flexGrow={1} />
                <Box display='flex'>
                    <Box flexGrow={1} />    
                    <ListItem sx={{width: 'unset', px: 0.5}}>
                        <ListItemButton sx={{height: 42}} onClick={toggleForceOpen}>
                            {open ? 
                                <SidePanelClose color='var(--nav-text-primary)' /> : 
                                <SidePanelOpen color='var(--nav-text-primary)' />
                            }
                        </ListItemButton>
                    </ListItem>
                </Box>
            </Drawer>
            {/* other drawer */}
            <Drawer
                open={!open && !!listExpanded}
                hideBackdrop
                PaperProps={{
                    sx: {
                        flexDirection: 'row',
                        bgcolor: 'var(--nav-layer-01)',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                    },
                    onMouseLeave: () => setListExpanded(null)
                }}
            >
                <Box minWidth={drawerWidth}/>
                <Box>
                    <Toolbar variant='dense' />
                    <NavSideMenu 
                        label={navItems.find(c => c.label === listExpanded)?.label}
                        items={navItems.find(c => c.label === listExpanded)?.children ?? []}
                    />
                </Box>
            </Drawer>
            {/* main */}
            <Toolbar variant='dense' />
            <Box 
                display='flex'
                component='main'
                bgcolor='var(--nav-background)'
                >
                <Box minWidth={drawerWidth} sx={{
                    transition: 'min-width 0.3s ease-in',
                    '@media (max-width: 768px)': {display: 'none'}
                }} />
                <Box 
                    position="relative"
                    bgcolor='var(--background)'
                    overflow='hidden'
                    width='100%'
                    minHeight='calc(100vh - 40px)'
                    sx={{borderTopLeftRadius: 10, '@media (max-width: 768px)': {borderTopLeftRadius: 0}}}
                    >
                    <Outlet />

                    {/* Payment failed banner */}
                    {subscriptionIsPastDue && <Banner 
                        type="error" 
                        title={t('inlineNotifications.billing.paymentFailed.title')}
                        message={t('inlineNotifications.billing.paymentFailed.message')}
                        canDismiss={true}
                        action={{
                            label: t('components.buttons.payNow'),
                            onClick: () => navigate('settings/billing')
                        }}
                    />}

                    {/* Pay requirement banner */}
                    {showRequirementBanner() && <Banner 
                        type={showRequirementBanner()?.status === 'past_due' ? 'error' : 'warning'}
                        title={t(`components.banners.payRequirement.${showRequirementBanner()?.status}.title`)}
                        message={t(`components.banners.payRequirement.${showRequirementBanner()?.status}.message`)}
                        action={{
                            label: t('components.buttons.reviewAccount'),
                            onClick: () => navigate('settings/1fitpay')
                        }}
                    />}

                    {/* Pending invite banner */}
                    {hasTeamInvite && <Banner 
                        type="info" 
                        title={t('inlineNotifications.team.pendingInvite.title')}
                        message={t('inlineNotifications.team.pendingInvite.message', {team: teamInvitation?.team.name, name: teamInvitation?.invited_by})}
                        canDismiss={true}
                        action={{
                            label: t('components.buttons.reviewInvite'),
                            onClick: () => navigate('settings/teams')
                        }}
                    />}
                    {/* Mobile warning banner */}
                    {isMobile && <Banner 
                        type="warning"
                        position="top"
                        title={t('components.banners.mobile.title')}
                        message={t('components.banners.mobile.message')}
                    />}
                </Box>
            </Box>

            <AppUpdatedModal />
        </>
    );
}