import { Navigate } from "react-router-dom";
import BusinessSetupPage from "@/pages/business/setup_page";
import PaymentsPage from "@/pages/business/payments/payments_page";
import PerformancePage from "@/pages/business/payments/performance_page";
import ProductsTab from "@/pages/business/payments/products/product_tab";
import TransactionsTab from "@/pages/business/payments/transactions/transaction_tab";


export const businessRoutes = {
  path: "business",
  children: [
    {
      index: true,
      element: <Navigate to="setup" replace />,
    },
    {
      path: "setup",
      element: <BusinessSetupPage />
    },
    {
      path: "payments",
      element: <PaymentsPage />,
      children: [
        {
          index: true,
          element: <Navigate to="products" replace />,
        },
        {
          path: "products",
          element: <ProductsTab />
        },
        {
          path: "transactions",
          element: <TransactionsTab />
        }
      ]
    },
    {
      path: "performance",
      element: <PerformancePage />
    },
  ]
}