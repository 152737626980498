import { useTranslation } from "react-i18next";

// Helpers
import { capitalize, capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { ITeamInvitation, ITeamMember } from "@/interfaces/team/team";

// Components
import TeamMenu from "./team_menu";
import { Box } from "@mui/material";
import Avatar from "@/components/avatar";
import { ColDef } from "@/components/datatable";


const useTeamTableHeaders = (): ColDef<ITeamMember | ITeamInvitation>[] => {

    const { t } = useTranslation();
    
    function isMember(item: any): item is ITeamMember {
        return (item as ITeamMember).total_clients !== undefined;
    }

    const statusColor = (status: string) => {
        switch (status) {
            case 'pending':
                return 'var(--text-placeholder)'
            case 'active':
                return 'var(--support-success)'
            case 'suspended':
                return 'var(--support-error)'
        }
            
    }

    const headers: ColDef<ITeamMember|ITeamInvitation>[] = [
        {
            field: 'avatar',
            headerName: t('components.dataTable.headers.name'),
            render: (row, index) => isMember(row) && row.name ? 
                <Box display="flex" alignItems="center">
                    <Avatar index={index} name={row.name as string} src={row.avatar ?? ''} /> 
                    <span style={{marginLeft: '12px'}}>{capitalizeWords(row.name)}</span>
                </Box> : null
        },
        {
            field: 'email',
            headerName: t('components.dataTable.headers.email'),
        },
        {
            field: 'role',
            headerName: t('components.dataTable.headers.role'),
            render: (row) => capitalize(row.role)
        },
        {
            field: 'total_clients',
            headerName: t('components.dataTable.headers.currentClients'),
            render: (row) => <span>{isMember(row) ? row.total_clients : 0}</span>
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            render: (row) => 
                <span style={{color: statusColor(row.status)}}>
                    {t(`enums.teamMemberStatus.${row.status}`)}
                </span>
        },
        {
            field: 'actions',
            headerName: '',
            render: (row) => row.role === 'owner' ? null : <TeamMenu row={row} />
        }
    ];

    return headers;
}

export default useTeamTableHeaders;