import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITeamMember } from "@/interfaces/team/team";
import { useGetClientsQuery } from "@/repositories/client";
import { useDeleteTeamMemberMutation } from "@/repositories/team_member";
import { selectPaymentGatewayConfigured } from "@/repositories/storefront";
import { InlineNotification } from "@/components/notification/notification";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import Checkbox from "@/components/checkbox";


interface _DeleteTeamMemberProps {
    open: boolean;
    onClose: (() => void);
    member: ITeamMember;
}

interface _DeleteMemberForm {
    retain: boolean;
}

const DeleteTeamMemberModal: FC<_DeleteTeamMemberProps> = ({
    open,
    onClose,
    member
}) => {

    const { t } = useTranslation();
    const { data: clients } = useGetClientsQuery(member.id)
    const [deleteTeamMember, { isLoading }] = useDeleteTeamMemberMutation();

    const formMethods = useForm<_DeleteMemberForm>({
        mode: 'onBlur',
        defaultValues: {
            retain: false,
        }
    });

    const retain = formMethods.watch('retain');
    const disableDelete = (retain && clients?.some((c) => c.product || c.checkout_session)) ?? false;

    const handleClose = () => {
        onClose();
        formMethods.reset();
    }

    const submit = (data: _DeleteMemberForm) => {
        deleteTeamMember({id: member.id, retain: data.retain}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.team.memberDeleted.title'), 
                message: t('notifications.team.memberDeleted.message', {name: member.name})})
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            handleClose();
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={<Trans
                i18nKey="modals.deleteTeamMember.title"
                components={{ strong: <strong /> }}
                values={{ name: member.name }}
            />}
            text={
                <Trans
                    i18nKey="modals.deleteTeamMember.text"
                    components={{ strong: <strong /> }}
                    values={{ name: member.name, number: member.total_clients }}
                />
            }
            children={<FormProvider {...formMethods}>
                    <DeleteTeamMembmerForm showWarning={disableDelete} isLoading={isLoading} />
                </FormProvider>}
            action1={{
                kind: 'danger',
                label: t('components.buttons.deleteTeamMember'),
                icon: <TrashCan />,
                onClick: formMethods.handleSubmit(submit),
                loading: isLoading,
                disabled: disableDelete
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '640px', background: 'var(--background)'}}}
            />
    )
}

export default DeleteTeamMemberModal;

interface _DeleteTeamMemberFormsProps {
    showWarning: boolean;
    isLoading: boolean;
}

const DeleteTeamMembmerForm: FC<_DeleteTeamMemberFormsProps> = ({
    showWarning,
    isLoading
}) => {

    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));
    const { control, watch } = useFormContext<_DeleteMemberForm>();
    const retain = watch('retain');

    return (
        <Box display="flex" flexDirection="column">
            <Checkbox 
                name="retain"
                control={control}
                label={t('components.checkbox.deleteTeamMember')}
                disabled={isLoading}
                sx={{marginBottom: '24px'}}
                />
            {paymentsConfigured && retain && showWarning && <InlineNotification 
                type="warning"
                title={t('inlineNotifications.team.retainHasPayments.title')}
                message={<Trans
                    i18nKey="inlineNotifications.team.retainHasPayments.message"
                    components={{strong: <strong />}}
                    />}
                />}
        </Box>
    )
}