import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { Options } from "@/components/menu";
import { ApiError } from "@/interfaces/api/error";
import { useSendTransactionReceiptMutation } from "@/repositories/transaction";
import { ITransaction, ITransactionQueryParams } from "@/interfaces/transaction/transaction";

// Components
import DetailsModal from "./details_modal";
import OverflowMenu from "@/components/overflow_menu";
import { Box, CircularProgress } from "@mui/material";
import RefundModal from "@/components/transactions/refund_modal";


interface _TransactionsMenuProps {
    row: ITransaction;
    params?: ITransactionQueryParams
}

const TransactionMenu: FC<_TransactionsMenuProps> = ({
    row,
    params
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const disableRefund = row.status === 'refunded' || row.status === 'failed';
    const [sendReceipt, { isLoading: sendingReceipt }] = useSendTransactionReceiptMutation();

    const handleSendReceipt = () => {
        sendReceipt(row.id).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.transaction.receipt.title'),
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const options: Options[] = [
        {name: t('components.menuItems.refund'), disabled: disableRefund, action: () => setOpen('refund')},
        {name: t('components.menuItems.sendReceipt'), disabled: sendingReceipt || row.status === 'pending' || row.status === 'failed', action: handleSendReceipt},
        {name: t('components.menuItems.transactionDetails'), action: () => setOpen('details')},
    ];

    return (
        <Box display="flex" justifyContent="flex-end">
            {!sendingReceipt ? <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete
                /> : <CircularProgress size={16} sx={{color: 'var(--text-primary)', padding: '8px'}} />}
            {open === 'refund' && 
                <RefundModal 
                    open={open === 'refund'} 
                    onClose={() => setOpen(null)}
                    transaction={row}
                    params={params}
                    />}
            {open === 'details' && 
                <DetailsModal 
                    open={open === 'details'} 
                    onClose={() => setOpen(null)}
                    transaction={row}
                    />}
            
        </Box>
    )
}

export default TransactionMenu;