import { FC } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { ITeamMember } from "@/interfaces/team/team";

// Styles
import { Error, UserAccessUnlocked } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _SuspendTeamMemberModalProps {
    open: boolean;
    onClose: (() => void);
    member: ITeamMember;
    isLoading: boolean;
    onSubmit: () => void;
}

const SuspendTeamMemberModal: FC<_SuspendTeamMemberModalProps> = ({
    open,
    onClose,
    member,
    isLoading = false,
    onSubmit,
}) => {

    const { t } = useTranslation();
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.suspendTeamMember.title', {context: `${member.status == 'active'}`})}
            text={t('modals.suspendTeamMember.text', {context: `${member.status == 'active'}`, name: member?.name})}
            action1={{
                label: t(`components.buttons.${member.status == 'active' ? 'suspendTeamMember' : 'reinstateTeamMember'}`),
                icon: member.status == 'active' ? <Error /> : <UserAccessUnlocked />,
                onClick: onSubmit,
                loading: isLoading,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
    )
}

export default SuspendTeamMemberModal;