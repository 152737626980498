import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { selectStorefrontCurrency } from "@/repositories/storefront";

// Styles
import { Money } from "@carbon/icons-react";

// Components
import PayoutModal from "./payout_modal";
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";


interface _BalanceCardProps {
    available_balance: number;
    pending_balance: number;
    isLoading: boolean; 
}

const BalanceCard: FC<_BalanceCardProps> = ({
    available_balance,
    pending_balance,
    isLoading = false
}) => {

    const [open, setOpen] = useState(false);
    const accountCurrency = useSelector((state) => selectStorefrontCurrency(state, undefined)) ?? '';
    const currencySymbol = getCurrencySymbol(accountCurrency?.toUpperCase());

    const hasNegativeBalance = available_balance && available_balance < 0;
    const insufficientFunds = (available_balance + (pending_balance < 0 ? pending_balance : 0)) <= 0;

    return (
        <Box
            display="flex" 
            flexDirection="column"
            flexGrow={1}
            border="solid 1px var(--border-subtle-01)"
            borderRadius="6px"
            bgcolor="var(--layer-01)"
            maxWidth="250px"
            >

            {/* Header */}
            <Box display="flex" alignItems="center" padding="8px 16px" height="32px" justifyContent="space-between" borderBottom="solid 1px var(--border-subtle-01)">
                <span className="body-02-compact">{t('pages.business.performance.balanceCard.title')}</span>
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.payoutNow')}
                    endIcon={<Money />}
                    minWidth={false}
                    disabled={isLoading || insufficientFunds}
                    onClick={() => setOpen(true)}
                    />
                    {open && <PayoutModal
                        open={open}
                        onClose={() => setOpen(false)}
                        available_balance={available_balance}
                        pending_balance={pending_balance}
                        currency={accountCurrency}
                        />}
            </Box>

            {/* Financials */}
            <Box display="flex" padding="24px 24px 24px 24px" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <span className="heading-07-compact" style={{marginBottom: '8px'}}>{t('pages.business.performance.balanceCard.totalBalance')}</span>
                    {isLoading ? 
                        <Skeleton variant="rectangular" width={100} height="28px" sx={{marginBottom: '8px'}} /> :
                        <span className="heading-05" style={{marginBottom: '6px'}}>
                        {`${hasNegativeBalance ? '-' : ''}${currencySymbol}${(available_balance) * (hasNegativeBalance ? -1 : 1)}`}
                    </span>}
                    {isLoading ? 
                        <Skeleton variant="rectangular" width={100} height="20px" /> :
                        <span className="body-02">{t('pages.business.performance.balanceCard.pendingBalance', {
                            currency: currencySymbol,
                            amount: pending_balance
                        })}</span>}
                </Box>
            </Box>
        </Box>
    );

}

export default BalanceCard;