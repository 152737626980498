import { FC, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useRenewSubscriptionMutation } from "@/repositories/subscription";
import { selectIsTrial, selectEndsAt, selectPaymentsEnabled } from "@/store/subscription";

// Styles
import { Add, ArrowRight } from "@carbon/icons-react"

// Components
import { Box } from "@mui/material"
import Button from "@/components/button";
import PayNowModal from "./pay_now_modal";
import CancelSubscriptionModal from "./cancel_subscription_modal";
import AddPaymentModal from "@/components/billing/add_payment_modal";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";
import ManageSubscriptionsModal from "./manage_subscriptions_modal";


const ActionBar: FC = () => {

    const { t } = useTranslation();
    const endsAt = useSelector(selectEndsAt);
    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const paymentModuleEnabled = useSelector(selectPaymentsEnabled);
    const ends_at = useSelector(selectEndsAt);
    const isTrial = useSelector(selectIsTrial);
    const [open, setOpen] = useState<string|null>(null);
    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined);
    const [renewSubscription, { isLoading }] = useRenewSubscriptionMutation();

    const hasFailure = paymentMethods?.some((item) => item.failure);

    const submitRenewSubscription = () => {
        renewSubscription().unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t(`notifications.billing.${isTrial ? 'trialResumed' : 'subscriptionRenewed'}.title`), 
                message: t(`notifications.billing.${isTrial ? 'trialResumed' : 'subscriptionRenewed'}.message`)
            })
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" alignItems="center" rowGap="16px">

            {!endsAt && !paymentModuleEnabled && <Button
                kind="danger-ghost"
                size="small"
                label={t(`components.buttons.${isTrial ? 'cancelTrial' : 'cancelSubscription'}`)}
                onClick={() => setOpen('cancel')}
                disabled={hasTeam && !isTeamOwner}
                />}
            {open === 'cancel' && <CancelSubscriptionModal 
                open={open === 'cancel'} 
                onClose={() => setOpen(null)}
                />}

            {!endsAt && paymentModuleEnabled && <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.manageSubscriptions')}
                onClick={() => setOpen('manage')}
                disabled={hasTeam && !isTeamOwner}
                />}
            {open === 'manage' && <ManageSubscriptionsModal 
                open={open === 'manage'} 
                onClose={() => setOpen(null)}
                />}

            <FullScreenLoader show={isLoading} loadingText={t(isTrial ? 'billing.reactivatingTrial': 'billing.renewingSubscription')} />
            {ends_at && <Button
                size="small"
                label={t(`components.buttons.${isTrial ? 'reactivateTrial' : 'renewSubscription'}`)}
                endIcon={<ArrowRight />}
                onClick={submitRenewSubscription}
                loading={isLoading}
                />}

            {/* Spacer */}
            <Box flexGrow={1} />

            {((hasTeam && isTeamOwner) || !hasTeam) && <Button
                kind="tertiary"
                size="small"
                label={t('components.buttons.addPaymentMethod')}
                endIcon={<Add />}
                onClick={() => setOpen('add')}
                disabled={isLoading}
                />}
            {open === 'add' && <AddPaymentModal 
                open={open === 'add'} 
                onClose={() => setOpen(null)}
                />}

            {hasFailure && <Button
                kind="primary"
                size="small"
                label={t('components.buttons.retryPayment')}
                endIcon={<Add />}
                onClick={() => setOpen('retry')}
                disabled={isLoading}
                sx={{marginLeft: '16px'}}
                />}
            {open === 'retry' && <PayNowModal 
                open={open === 'retry'} 
                onClose={() => setOpen(null)}
                />}

        </Box>
    )
}

export default ActionBar;