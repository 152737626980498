import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { selectHasTeam } from "@/store/team";
import { ITransaction } from "@/interfaces/transaction/transaction";

// Components
import Tag from "@/components/tag";
import Link from "@/components/link";
import { ColDef } from "@/components/datatable";
import TransactionMenu from "@/components/transactions/transaction_menu";


const useTransactionTableHeaders = (): ColDef<ITransaction>[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const tagColor = (status: string) => {
        switch (status) {
            case 'pending':
                return 'grey';
            case 'succeeded':
                return 'green';
            case 'failed':
                return 'red';
            case 'partially_refunded':
                return 'cyan';
            case 'refunded':
                return 'orange';
            default:
                return 'grey';
        }
    }

    const headers: ColDef<ITransaction>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '15%',
            sortable: true,
            render: (row) => new Date(row.paid_at).toLocaleDateString()
        },
        ...hasTeam ? [{
            field: 'coach_name',
            headerName: t('components.dataTable.headers.coach'),
            width: '15%',
            render: (row: ITransaction) => capitalizeWords(row.coach_name ?? '-')
        }] : [],
        {
            field: 'customer_name',
            headerName: t('components.dataTable.headers.customer'),
            width: '15%',
            // sortable: true,
            render: (row) => row.customer_name ? capitalizeWords(row.customer_name) : '-'
        },
        {
            field: 'amount',
            headerName: t('components.dataTable.headers.amount'),
            width: '15%',
            // sortable: true,
            render: (row) => row.amount ? `${row.amount.toFixed(2)} ${row.currency.toUpperCase()}` : '-'
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            width: '15%',
            sortable: true,
            render: (row) => <Tag label={t(`enums.transactionStatus.${row.status}`)} colour={tagColor(row.status)} />
        },
        {
            field: 'invoice_url',
            headerName: t('components.dataTable.headers.invoice'),
            width: '15%',
            render: (row: ITransaction) => <Link 
                label={t('components.links.viewInvoice')} 
                to={row.invoice_url && row.status !== 'failed' ? row.invoice_url : ''} 
                newWindow 
                />
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: ITransaction) => <TransactionMenu row={row} />
        }
    ];

    return headers;
}

export default useTransactionTableHeaders;