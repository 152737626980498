import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Card } from "@mui/material";
import BuyPaymentsModuleModal from "./buy_payments_module_modal";


const PaymentsModuleHeader: FC = () => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            {/* Title */}
            <span className="heading-07-compact">{t('pages.settings.billing.paymentSummary')}</span>
            <Box height={8} />

            {/* Summary Card */}
            <Card elevation={0} sx={{display: 'flex', justifyContent: 'space-between', padding: '24px 32px', border: 'solid 1px var(--teal-60)', borderRadius: '6px'}}>

                {/* Title and description */}
                <Box display="flex" flexDirection="column">
                    <span className="heading-06" style={{marginBottom: '20px'}}>{t('pages.business.setup.title')}</span>
                    <span className="body-02">{t('pages.settings.billing.paymentsModule.productsText')}</span>
                    <span className="body-02">{t('pages.settings.billing.paymentsModule.paymentsText')}</span>
                    <span className="body-02">{t('pages.settings.billing.paymentsModule.brandingText')}</span>
                    <span className="body-02" style={{marginBottom: '6px'}}>{t('pages.settings.billing.paymentsModule.performanceText')}</span>
                    {/* <span className="body-02" style={{marginBottom: '6px'}}>{t('pages.settings.billing.paymentsModule.connectText')}</span> */}
                    <span className="label-text-02" style={{color: 'var(--text-placeholder)'}}>{t('components.businessSetup.action.processingFees')}</span>
                </Box>

                {/* Pricing */}
                <Box display="flex" flexDirection="column" paddingTop="8px">

                    {/* <span className="heading-06-compact">{`£10 /${t('timeDate.month')}`}</span>
                    <span className="label-text-02" style={{color: 'var(--text-placeholder)'}}>{t('components.businessSetup.action.text')}</span> */}

                    <Box display="flex" flexGrow={1} />

                    <Button
                        kind="ghost"
                        size="small"
                        label={t('components.buttons.enablePayments')}
                        endIcon={<ArrowRight />}
                        onClick={() => setOpen(true)}
                        />
                        {open && <BuyPaymentsModuleModal open={open} onClose={() => setOpen(false)} />}

                </Box>

            </Card>

            <Box height="24px" />
        </>
    )
}

export default PaymentsModuleHeader;