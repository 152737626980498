import baseApi from '@/repositories/base_api';

// Helpers
import { dateIsSameOrBeforeNow } from '@/_helpers/time_functions';

// Interfaces
import Workout from '@/interfaces/workout/workout';
import { IAPIResponse } from '@/interfaces/api/response';


export const workoutApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkouts: build.query<Workout[], string>({
      query: (id) => ({ url: `clients/${id}/workouts` }),
      transformResponse: (response: IAPIResponse<Workout[]>) => 
        response.data.filter((item) => dateIsSameOrBeforeNow(item.started))
    }),
    getWorkout: build.query<Workout, {client_id: number, workout_id: string}>({
      query: ({client_id, workout_id}) => ({ url: `clients/${client_id}/workouts/${workout_id}` }),
      transformResponse: (response: IAPIResponse<Workout>) => response.data,
    }),
  }),
})

export const { 
  useGetWorkoutsQuery,
  useGetWorkoutQuery,
} = workoutApi;
