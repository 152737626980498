import { FC } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPaymentProduct } from "@/interfaces/client/client";
import { useGetProductsQuery } from "@/repositories/product";
import { useChangeClientSubscriptionMutation } from "@/repositories/client_subscription";
import { clientSubscriptionChangeSchema, IClientSubscriptionChangeForm } from "@/interfaces/client_subscription/client_subscription_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import SubscriptionChangeForm from "./subscription_change_form";


interface _SubscriptionChangeModalProps {
    open: boolean;
    onClose: () => void;
    client_id: number;
    product: IPaymentProduct;
}

const SubscriptionChangeModal: FC<_SubscriptionChangeModalProps> = ({
    open,
    onClose,
    client_id,
    product
}) => {

    const { data: products } = useGetProductsQuery();
    const activeProducts = products?.filter((p) => p.active && p.id !== product.id) ?? [];
    const [changeSubscription, { isLoading }] = useChangeClientSubscriptionMutation();

    const formMethods = useForm<IClientSubscriptionChangeForm>({
        resolver: zodResolver(clientSubscriptionChangeSchema),
        mode: 'onBlur',
        defaultValues: {
            payment_product: '',
            end_at: 'end_of_period',
            refund: 'none'
        }
    });

    const selectedProduct = formMethods.watch('payment_product');
    const disableChange = selectedProduct !== '' && activeProducts.find((product) => product.id === selectedProduct)?.currency !== product.currency;

    const handleCancelSubscription = (data: IClientSubscriptionChangeForm) => {
        const old_product = product?.product_name || '';
        changeSubscription({client_id, data}).unwrap().then((response) => {
            showToast({
                type: 'success',
                title: t('notifications.client.productChanged.title', {context: `${data.end_at === 'now'}`}),
                message: <Trans
                    i18nKey='notifications.client.productChanged.message'
                    components={{strong: <strong />}}
                    values={{
                        context: `${data.end_at === 'now'}`,
                        name: `${response.first_name} ${response.last_name}`, 
                        old: old_product,
                        new: response.payment_product?.product_name
                    }}/>
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.clientChangeProduct.title')}
            text={t('modals.clientChangeProduct.text')}
            children={
                <FormProvider {...formMethods}>
                    <SubscriptionChangeForm product={product} products={activeProducts} client_id={client_id} disableChange={disableChange} />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.change'),
                icon: <ArrowRight />,
                loading: isLoading,
                disabled: disableChange,
                onClick: formMethods.handleSubmit(handleCancelSubscription)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '540px', background: 'var(--background)'}}}
            />
    )
}

export default SubscriptionChangeModal;