import { t } from "i18next";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { Provider } from "@/interfaces/storefront/storefront";
import IConnectProviderForm from "@/interfaces/storefront/connect_provider_form";
import { useConnectProviderMutation, useOnboardProviderMutation } from "@/repositories/storefront";

// Styles
import StripeCard from "@/assets/payment_providers/stripe_card.svg";
import { ArrowRight, ArrowUpRight, Rocket } from "@carbon/icons-react";

// Components
import Link from "@/components/link";
import HelpModal from "./help_modal";
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";
import FeesModal from "@/components/payments/fees_modal";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";


interface _StripeProviderProps {
    storefront_id: number;
    can_reconnect: boolean;
}

const StripeProvider: FC<_StripeProviderProps> = ({
    storefront_id,
    can_reconnect
}) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const [loaded, setLoaded] = useState(false);
    const [onboard, { isLoading: isOnboarding }] = useOnboardProviderMutation();
    const [connectProvider, { isLoading: isReconnecting }] = useConnectProviderMutation();

    const handleSetupStripe = () => {
        setOpen('loader');
        const payload: IConnectProviderForm = {
            provider: 'stripe' as Provider,
        }
        onboard({id: storefront_id, data: payload}).unwrap().then((response) => {
            window.location.href = response.url;
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
            setOpen(null);
        });
    }

    const handleReconnectStripe = () => {
        setOpen('loader');
        const payload: IConnectProviderForm = {
            provider: 'stripe' as Provider,
            oauth_code: null,
        }

        setTimeout(() => {
            connectProvider({id: storefront_id, data: payload}).unwrap().then((response) => {
                navigate('/business/payments/products', {replace: true});
                showToast({
                    type: 'success', 
                    title: t('notifications.storefront.providerConnected.title', {provider: capitalize(response.provider ?? '')}), 
                    message: t('notifications.storefront.providerConnected.message', {provider: capitalize(response.provider ?? '')})
                });
            }).catch((error: ApiError) => {
                showToast({type: 'error', apiError: error.type});
                setOpen(null);
            });
        }, 1500);
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column"
            flex={1}
            padding="24px 32px"
            border="solid 1px var(--border-subtle-01)" 
            borderRadius="6px"
            bgcolor="var(--layer-01)"
            sx={{textAlign: 'left'}}
            >

            <Box display="flex" alignItems="center" sx={{marginBottom: '12px'}}>
                {!loaded && <Skeleton variant="rounded" width="35px" height="23.33px" style={{marginRight: '8px'}} />}
                <img src={StripeCard} alt="Stripe" onLoad={() => setLoaded(true)} style={{maxWidth: '35px', marginRight: '8px'}} />
                <span className="heading-05">{t('pages.settings.storefront.providers.stripe.title')}</span>
            </Box>

            <Box height="12px" />
            <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t('pages.settings.storefront.providers.stripe.text')}</span>

            <Box height="32px" />

            <Box display="flex" justifyContent="space-between" alignItems="center">

                <Link
                    label={t('components.links.transactionFees')}
                    icon={<ArrowUpRight />}
                    to=""
                    onClick={() => setOpen('fees')}
                    />
                    {open === 'fees' && <FeesModal
                        open={open === 'fees'}
                        onClose={() => setOpen(null)}
                        />}
                
                {/* Links */}
                <Box display="flex" columnGap="16px">
                    <Link
                        label={t('components.links.helpMeGetStarted')}
                        icon={<Rocket />}
                        to=""
                        onClick={() => setOpen('get_started')}
                        />
                        {open === 'get_started' && <HelpModal
                            open={open === 'get_started'}
                            onClose={() => setOpen(null)}
                            provider="stripe"
                            onConnect={can_reconnect ? handleReconnectStripe : handleSetupStripe}
                            />}
                    <Button
                        size="small"
                        label={t(`components.buttons.${can_reconnect ? 'reconnectStripe' : 'connectStripe'}`)}
                        endIcon={<ArrowRight />}
                        loading={isOnboarding || isReconnecting}
                        onClick={can_reconnect ? handleReconnectStripe : handleSetupStripe}
                        />
                        {open == 'loader' && <FullScreenLoader
                            show={open == 'loader'}
                            loadingText={t('pages.business.chooseProvider.connectingToStripe')}
                            />}

                </Box>

            </Box>
        </Box>
    )
}

export default StripeProvider;