import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { useGetClientQuery, useOnboardClientMutation } from "@/repositories/client";
import { ApiError } from "@/interfaces/api/error";

// Components
import Box from "@mui/material/Box";
import OnboardingModal from "./onboarding_modal";
import { InlineNotification } from '@/components/notification/notification';


export default function OnboardingNotice() {

    const { id } = useParams();
    const [open, setOpen] = useState<boolean>(false);
    const { data: client, isLoading } = useGetClientQuery(Number(id));
    const [ onboardClient, { isLoading: onboardInProgress } ] = useOnboardClientMutation();

    const isActive = client?.active;
    const hasProduct = client?.payment_product?.status === 'trialing' || !!client?.checkout_session

    // Onboarding action
    const onboard = () => onboardClient(Number(id)).unwrap()
        .then((d) => showToast({
            type: 'success',
            title: t('notifications.client.onboarded.title'),
            message: t('notifications.client.onboarded.message', {name: `${d.first_name} ${d.last_name}`})
        }))
        .catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })

    // Dont render banner if onboarded or data not yet loaded
    if (isLoading || !!(client?.onboarded_at || !isActive)) return <></>;

    return (
        <>   
            {/* banner */}
            <Box>
                <InlineNotification 
                    type='warning'
                    title={t('components.banners.clientNotOnboarded.title')} 
                    message={t('components.banners.clientNotOnboarded.message')}
                    action={{
                        label: t('components.buttons.onboardClient'),
                        loading: onboardInProgress,
                        onClick: hasProduct ? () => setOpen(true) : onboard
                    }}
                /> 
                {open && <OnboardingModal
                    open={open}
                    onClose={() => setOpen(false)}
                    name={`${client?.full_name}`}
                    plan_start_date={client?.payment_product?.starts_at}
                    hasCheckout={!!client?.checkout_session}
                    isLoading={onboardInProgress}
                    onSubmit={onboard}
                    />}
            </Box>   
        </>
    )
}