import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

// Constants
import { checkinDayOptions, checkinFrequencyOptions, paymentScheduleOptions } from "@/_constants/client";

// Services and interfaces
import { useGetProductsQuery } from "@/repositories/product";
import { selectPaymentGatewayConfigured } from "@/repositories/storefront";
import { useGetCheckinTemplatesQuery } from "@/repositories/checkin_template";

// Components
import Grid from "@mui/material/Grid";
import TextInput from "@/components/text_input";
import DateInput from "@/components/date_input";
import EmailInput from "@/components/email_input";
import PhoneInput from "@/components/phone_input";
import RadioGroup from "@/components/radio_group";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";
import MultiselectInput from "@/components/multiselect_input";
import { InlineNotification } from "@/components/notification/notification";


interface ClientFormProps {
    disableProduct?: boolean;
    isOnboarded?: boolean;
    isVerified?: boolean;
    isConfirmed?: boolean;
    isReadonly?: boolean;
    onConfirm: () => void;
}

const ClientForm: FC<ClientFormProps> = ({
    disableProduct = false,
    isOnboarded = false,
    isVerified = false,
    isConfirmed = false,
    isReadonly = false,
    onConfirm
}) => {

    const { t } = useTranslation();
    const [enableStartDate, setEnableStartDate] = useState<boolean>(false);
    const { control, formState, watch, getValues, setValue } = useFormContext();
    const showEmailNotification = isOnboarded && !isVerified && formState.defaultValues?.email !== getValues('email');

    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));
    const { data: checkinTemplates } = useGetCheckinTemplatesQuery();
    const { data: products } = useGetProductsQuery();
    const defaultTemplate = checkinTemplates?.find(c => c.default);
    const defaultProduct = products?.find(p => p.default);
    const activeProducts = [{id: -1, name: t('components.select.options.product.noProduct')}, ...products?.filter((product) => product.active) ?? []];

    const productSelected = watch('payment_product').toString() !== '-1';
    const checkinFrequency = watch('checkin_frequency');
    const checkinDisabled = checkinFrequency === 'none';

    useEffect(() => {
        if (getValues('payment_product') === '-1' && defaultProduct && !disableProduct) {
            setValue('payment_product', defaultProduct.id); 
        }
        if (getValues('checkin_template_id') === '' && defaultTemplate) {
            setValue('checkin_template_id', defaultTemplate.id);
        }
    }, [defaultTemplate, defaultProduct, disableProduct, getValues, setValue]);

    const handleEnableStartDate = (value: string) => {
        if (value === 'now') {
            setValue('payment_start', '');
            setEnableStartDate(false);
        } else {
            setValue('payment_start', new Date().toISOString());
            setEnableStartDate(true);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextInput
                    control={control}
                    name='first_name'
                    label={t('inputs.titles.firstName')}
                    placeHolder="Ben"
                    disabled={isOnboarded || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name='last_name'
                    control={control}
                    label={t('inputs.titles.lastName')}
                    placeHolder="Smith"
                    disabled={isOnboarded || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <EmailInput
                    name='email'
                    control={control}
                    label={t('inputs.titles.emailAddress')}
                    placeHolder="ben@domain.com"
                    disabled={isVerified || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <PhoneInput
                    name='mobile'
                    control={control}
                    label={t('inputs.titles.phoneNumber')}
                    placeHolder="+44 1234 567890"
                    disabled={isOnboarded || isReadonly}
                />
            </Grid>
            {paymentsConfigured &&
                <Grid item xs={12}>
                    <SelectInput
                        name='payment_product'
                        control={control}
                        label={t('inputs.titles.product')}
                        items={activeProducts}
                        itemLabel="name"
                        itemKey="id"
                        itemValue="id"
                        disabled={isReadonly || disableProduct}
                    />
                </Grid>}
                {/* Start Date selection */}
            {productSelected && !disableProduct && <>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        label={t('components.radioGroup.titles.productStarts')}
                        defaultValue="now"
                        items={[
                            { value: 'now', label: t('components.radioGroup.options.uponPayment') },
                            { value: 'fixed', label: t('components.radioGroup.options.fixedDate') },
                        ]}
                        onChange={handleEnableStartDate}
                        />
                </Grid>
                <Grid item xs={6}>
                    <DateInput
                        name='payment_start'
                        control={control}
                        label={t('inputs.titles.startDate')}
                        tooltip={t('tooltips.productStartDate')}
                        useDayNomenclature
                        disabled={isReadonly || !enableStartDate}
                    />
                </Grid>
            </>}
            {(!paymentsConfigured || !productSelected) && <>
                <Grid item xs={6}>
                    <NumberInput
                        name='payment_amount'
                        control={control}
                        label={t('inputs.titles.paymentAmount')}
                        placeHolder="100"
                        decimalPlaces={2}
                        disabled={isReadonly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        name='payment_schedule'
                        control={control}
                        label={t('inputs.titles.paymentSchedule')}
                        items={paymentScheduleOptions}
                        allowEmpty
                        disabled={isReadonly}
                    />
                </Grid>
            </>}
            <Grid item xs={6}>
                <SelectInput
                    name='checkin_frequency'
                    control={control}
                    label={t('inputs.titles.checkinFrequency')}
                    items={checkinFrequencyOptions}
                    disabled={isReadonly}
                    onChange={() => setValue('checkin_days', [])}
                    />
            </Grid>
            <Grid item xs={6}>
                {checkinFrequency === 'weekly' ? 
                    <MultiselectInput
                        name='checkin_days'
                        control={control}
                        label={t('inputs.titles.checkinDay')}
                        items={checkinDayOptions}
                        disabled={isReadonly || checkinDisabled}
                        /> :
                    <SelectInput
                        name='checkin_days'
                        control={control}
                        label={t('inputs.titles.checkinDay')}
                        items={checkinDayOptions}
                        disabled={isReadonly || checkinDisabled}
                        />}
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    name='checkin_template_id'
                    control={control}
                    label={t('inputs.titles.checkinTemplate')}
                    items={checkinTemplates?.filter((c) => !c.draft && c.type === 'coach') ?? []}
                    itemLabel="name"
                    itemValue="id"
                    disabled={isReadonly || checkinDisabled}
                    />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name='notes'
                    control={control}
                    size='textarea'
                    label={t('inputs.titles.notes')}
                    placeHolder={t('inputs.placeholders.clientNotes')}
                    maxLength={2000}
                    minRows={5}
                    disabled={isReadonly}
                    />
            </Grid>
            {showEmailNotification && <Grid item xs={12}>
                <InlineNotification
                    type={isConfirmed ? 'success' : 'info'}
                    title={t('inlineNotifications.client.emailChanged.title')}
                    message={<Trans
                        i18nKey="inlineNotifications.client.emailChanged.message"
                        components={{ strong: <strong /> }}
                        values={{ old: formState.defaultValues?.email, new: getValues('email') }}
                    />}
                    action={{
                        label: t('components.buttons.confirm'),
                        disabled: isConfirmed,
                        onClick: () => isConfirmed ? undefined : onConfirm(),
                    }}
                    />
            </Grid>}

        </Grid>
    );
}

export default ClientForm;