import { useTranslation } from "react-i18next";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Interfaces
import IPayout from "@/interfaces/payout/payout";

// Components
import Tag from "@/components/tag";
import { ColDef } from "@/components/datatable";


const usePayoutTableHeaders = (): ColDef<IPayout>[] => {

    const { t } = useTranslation();
    
    const tagColor = (status: string) => {
        switch (status) {
            case 'paid':
                return 'green';
            case 'pending':
                return 'orange';
            case 'failed':
                return 'red';
            case 'canceled':
                return 'grey';
            default:
                return 'blue';
        }
    }

    const headers: ColDef<IPayout>[] = [
        {
            field: 'requested_at',
            headerName: t('components.dataTable.headers.requestedDate'),
            width: '15%',
            render: (row) => new Date(row.requested_at).toLocaleDateString()
        },
        {
            field: 'paid_at',
            headerName: t('components.dataTable.headers.paidDate'),
            width: '15%',
            render: (row) => row.paid_at ? new Date(row.paid_at).toLocaleDateString() : '-'
        },
        {
            field: 'amount',
            headerName: t('components.dataTable.headers.amount'),
            width: '15%',
            render: (row) => row.amount ? `${getCurrencySymbol(row.currency.toUpperCase())}${row.amount}` : '-'
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            width: '15%',
            sortable: true,
            render: (row) => <Tag label={t(`enums.payoutStatus.${row.status}`)} colour={tagColor(row.status)} />
                
        }
    ];

    return headers;
}

export default usePayoutTableHeaders;