import { FC, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IActivityPlan } from "@/interfaces/activity_plan/activity_plan";
import { useDeleteActivityPlansMutation, useGetActivityPlansQuery } from "@/repositories/activity_plan";

// Components
import NoPlans from "./no_plans";
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import useActivityPlanTableHeaders from "./plan_headers";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import DeletePlanModal from "@/pages/client/components/delete_modal";
import { useSelector } from "react-redux";
import { selectIsClientReadonly } from "@/repositories/client";



const ActivityPlansTab: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const headers = useActivityPlanTableHeaders();
    const readonly = useSelector((state) => selectIsClientReadonly(state, Number(id)));
    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const { data: list, isLoading } = useGetActivityPlansQuery(Number(id));
    const [deletePlans, { isLoading: isDeletingMany }] = useDeleteActivityPlansMutation();

    const handleOpenPlan = (plan: IActivityPlan) => {
        if (showBulkActions) return;
        navigate(`/clients/${id}/activity/${plan.id}`);
    }
    
    const handleDeleteMany = () => {
        deletePlans({id: Number(id), plans: selected}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.plan.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
        <Container sx={{py: '24px'}}>

            <DataTable
                data={list}
                dataKey="id"
                columns={headers}
                filter={{showSelect: !readonly}}
                filterKeys={["name"]}
                bulkActions={{
                    disableSelect: (plan: IActivityPlan) => plan.active,
                    action1: {
                        label: t('components.buttons.delete'), 
                        onClick: () => setOpen('deleteMany'),
                    },
                }}
                hideHeader
                localPagination 
                noDataMessage={<NoPlans message={t('components.dataTable.noData.noPlans')} disabled={readonly} />}
                noDataFoundMessage={<NoPlans message={t('components.dataTable.noData.foundDefault')} disabled={readonly} />}
                isLoading={isLoading}
                rowClick={handleOpenPlan}
            />
            {open === 'deleteMany' && <DeletePlanModal
                open={open == 'deleteMany'}
                onClose={()=> setOpen(null)}
                name=''
                count={selected.length}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
            />}

        </Container>
        </Box>
    )
}

export default ActivityPlansTab;