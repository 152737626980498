import { FC } from "react";
import { t } from "i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { IPayoutForm } from "@/interfaces/payout/payout_form";
import { ISelectOption } from "@/interfaces/components/select_option";

// Components
import { Box } from "@mui/material";
import SelectInput from "@/components/select_input";
import NumberInput from "@/components/number_input";


interface _PayoutFormProps {
    balance_limit: number;
    currencySymbol: string;
    onPayoutChange: (value: 'full'|'partial') => void;
}

const PayoutForm: FC<_PayoutFormProps> = ({
    balance_limit,
    currencySymbol,
    onPayoutChange
}) => {

    const { control, watch } = useFormContext<IPayoutForm>();

    const disableValue = watch('type') === 'full';
    
    const payoutOptions: ISelectOption<string>[] = [
        { id: 1, label: t('components.select.options.payoutType.full'), value: 'full' },
        { id: 2, label: t('components.select.options.payoutType.partial'), value: 'partial' },
    ];

    return (
        <Box display="flex" flexDirection="column">
            <SelectInput
                name="type"
                control={control}  
                label={t('components.select.titles.payout')}
                items={payoutOptions}
                onChange={onPayoutChange}
                />
            <NumberInput
                name="amount"
                control={control}
                label={t('inputs.titles.amount')}
                prefixText={currencySymbol}
                decimalPlaces={2}
                maxLength={balance_limit.toString().length}
                disabled={disableValue}
                />
        </Box>
    )
}

export default PayoutForm;