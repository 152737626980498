import { FC } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, Link, useSearchParams } from "react-router-dom";

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useRegisterMutation } from '@/repositories/registration';
import { IRegistrationForm, registrationSchema } from '@/interfaces/registration/registration_form';

// Styles
import { East } from '@mui/icons-material';
import styles from '@/layouts/auth.module.scss';

// Components
import Box from '@mui/material/Box';
import Button from '@/components/button';
import Checkbox from "@/components/checkbox";
import TextInput from '@/components/text_input';
import PhoneInput from '@/components/phone_input';
import EmailInput from '@/components/email_input';
import PasswordInput from "@/components/password_input";

const RegistrationDetailsPage: FC = () => {


    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams(); 
    const accessCode = searchParams.get('referral');
    const [register, { isLoading }] = useRegisterMutation();

    const { control, handleSubmit, setValue, setError } = useForm<IRegistrationForm>({
        resolver: zodResolver(registrationSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            access_code: accessCode || '',
            password: '',
            password_confirmation: '',
            terms_accepted: false
        }
    });

    const submitForm = (data: IRegistrationForm) => {
        register(data).unwrap().then((res) => {
            navigate('/register/success', { state: { id: res.user_id }})
        }).catch((error: ApiError) => {
            if (error.errors !== null && error.type == "validation_failed") {
                for (const [key, value] of Object.entries(error.errors)) {
                    setError(key, { type: 'api', message: value as string});
                }
            } else {
                showToast({type: 'error', apiError: error.type})            
            }        
        })
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            width="100%" 
            maxWidth="540px" 
            padding="48px 24px"
            boxSizing="border-box"
            textAlign="left"
            sx={{'@media (max-width: 768px)': {maxWidth: '100%', textAlign: 'center'}}}
            >

            {/* Title, subtitle and 40px spacer */}
            <span className="heading-05">{t('pages.registration.details.title')}</span>
            <span className="body-01" style={{color: 'var(--text-secondary)'}}>{t('pages.registration.details.subtitle')}</span>
            <Box height={40} />

            {/* Form */}
            <form onSubmit={handleSubmit(submitForm)} style={{width: '100%'}}>
                <EmailInput
                    name='email'
                    control={control}
                    label={t('inputs.titles.emailAddress')} 
                    placeHolder="coach@1fit.com"
                    tabIndex={1}
                    />
                <PhoneInput
                    name='phone_number'
                    control={control}
                    label={t('inputs.titles.phoneNumber')}
                    placeHolder={t('inputs.placeholders.phoneNumber')}
                    // tabIndex={2}
                    />
                <TextInput
                    name='first_name'
                    control={control}
                    label={t('inputs.titles.firstName')}
                    placeHolder="Joe"
                    tabIndex={3}
                    maxLength={50}
                    />
                <TextInput
                    name='last_name'
                    control={control}
                    label={t('inputs.titles.lastName')}
                    placeHolder="Bloggs"
                    tabIndex={4}
                    maxLength={50}
                    />
                <TextInput
                    name='access_code'
                    control={control}
                    label={t('inputs.titles.accessCode')}
                    placeHolder="0000000"
                    tabIndex={5}
                    maxLength={15}
                    />

                <PasswordInput
                    name='password'
                    control={control}
                    label={t('inputs.titles.password')} 
                    tabIndex={1}
                    placeHolder={t('inputs.placeholders.passwordNew')}
                    showRequirements
                    />
                <PasswordInput
                    name='password_confirmation'
                    control={control}
                    label={t('inputs.titles.passwordConfirmation')}
                    placeHolder={t('inputs.placeholders.passwordNew')}
                    tabIndex={2}
                    />

                {/* Terms checkbox */}
                <Checkbox
                    name='terms_accepted'
                    control={control}
                    label={
                        <Trans i18nKey="components.checkbox.termsAgreement">
                            I agree to the <Link to="https://1fit.com/terms" target="_blank">Terms and Conditions</Link> of 1FITUK Limited.
                        </Trans>
                    }
                    labelPlacement='end'
                    tabIndex={2}
                    onChange={(e) => setValue('terms_accepted', e)}
                    />

                {/* Spacer */}
                <Box height={40} />

                {/* Actions */}
                <Box className={styles.actions}>
                    <Box className={styles.actions__registration}>
                        <span className="body-02">{t('pages.registration.details.haveAccount')}</span>
                        <Link className="body-02" to={'/auth/login'}>{t('components.buttons.login')}</Link>
                    </Box>
                    <Button
                        type="submit"
                        label={t('components.buttons.next')}
                        endIcon={<East />}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleSubmit(submitForm)}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                        />
                </Box>
            </form>

                
        </Box>
    )
}

export default RegistrationDetailsPage;

