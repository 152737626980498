import { FC } from "react";
import { t } from "i18next";

// Styles
import guide from '@/assets/guides/stripe_migration_guide.pdf';
import { ArrowRight, Download, Help } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";


interface _HelpModalProps {
    open: boolean;
    onClose: () => void;
    provider: string;
    showConnect?: boolean;
    onConnect?: () => void;
}
const HelpModal: FC<_HelpModalProps> = ({
    open,
    onClose,
    provider,
    showConnect = true,
    onConnect
}) => {

    const videoUrl = 'https://www.youtube.com/watch?v=NvtQi71jyUI';
    const videoId = videoUrl.split('v=')[1] || videoUrl.split('youtu.be/')[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    const handleGoToGuide = () => {
        window.open('https://intercom.help/1fit-helpcentre/en/articles/10313485-migrating-your-subscriptions-from-stripe', '_blank');
    }

    const handleDownloadGuide = () => {
        const guideUrl = guide;
        const link = document.createElement('a');
        link.href = guideUrl;
        link.download = 'migration_guide.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.stripeMigration.title')}
            text={t('modals.stripeMigration.text')}
            children={
                <Box display="flex" marginBottom="12px" width='100%'>
                    <iframe width='100%' style={{aspectRatio: '16/9'}} src={embedUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Box>
            }
            action1={onConnect && showConnect ? {
                label: t('components.buttons.connectStripe'),
                icon: <ArrowRight />,
                onClick: onConnect,
            } : undefined}
            action2={{
                kind: "ghost",
                label: t('components.buttons.downloadMigrationGuide'),
                icon: <Download />,
                onClick: handleDownloadGuide,
            }}
            action3={{
                kind: "ghost",
                label: t('components.buttons.helpStripeMigration'),
                icon: <Help />,
                onClick: handleGoToGuide,
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '950px'}}}
            />
    )
}

export default HelpModal;