import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

// Constants
import { checkinDayOptions, checkinFrequencyOptions, paymentScheduleOptions } from "@/_constants/client";

// Services and interfaces
import { selectCurrency } from "@/store/settings";
import { useGetProductsQuery } from "@/repositories/product";
import { selectPaymentGatewayConfigured } from "@/repositories/storefront";
import { useGetCheckinTemplatesQuery } from "@/repositories/checkin_template";

// Components
import { Box, Grid } from "@mui/material";
import DateInput from "@/components/date_input";
import RadioGroup from "@/components/radio_group";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";
import MultiselectInput from "@/components/multiselect_input";


const ConvertForm: FC = () => {

    const { t } = useTranslation();
    const currency = useSelector(selectCurrency);
    const { control, watch, getValues, setValue } = useFormContext();
    const [enableStartDate, setEnableStartDate] = useState<boolean>(false);
    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));


    const { data: checkinTemplates } = useGetCheckinTemplatesQuery();
    const { data: products } = useGetProductsQuery();
    const defaultTemplate = checkinTemplates?.find(c => c.default);
    const defaultProduct = products?.find(p => p.default);
    const activeProducts = [{id: -1, name: t('components.select.options.product.noProduct')}, ...products?.filter((product) => product.active) ?? []];

    const productSelected = watch('payment_product').toString() !== '-1';
    const checkinFrequency = watch('checkin_frequency');
    const checkinDisabled = checkinFrequency === 'none';

    useEffect(() => {
        if (getValues('payment_product') === '-1' && defaultProduct) {
            setValue('payment_product', defaultProduct.id);
        }
        if (getValues('checkin_template_id') === '' && defaultTemplate) {
            setValue('checkin_template_id', defaultTemplate.id);
        }
    }, [defaultTemplate, defaultProduct, getValues, setValue]);

    const handleEnableStartDate = (value: string) => {
        if (value === 'now') {
            setValue('payment_start', '');
            setEnableStartDate(false);
        } else {
            setValue('payment_start', new Date().toISOString());
            setEnableStartDate(true);
        }
    }

    return (
        <Box display="flex" flexDirection="column" rowGap="16px">
            {paymentsConfigured &&
                <SelectInput
                    name='payment_product'
                    control={control}
                    label={t('inputs.titles.product')}
                    items={activeProducts}
                    itemLabel="name"
                    itemKey="id"
                    itemValue="id"
                />}
            {productSelected && <>
                <RadioGroup
                    row
                    label={t('components.radioGroup.titles.productStarts')}
                    defaultValue="now"
                    items={[
                        { value: 'now', label: t('components.radioGroup.options.uponPayment') },
                        { value: 'fixed', label: t('components.radioGroup.options.fixedDate') },
                    ]}
                    onChange={handleEnableStartDate}
                    />
                <DateInput
                    name='payment_start'
                    control={control}
                    label={t('inputs.titles.startDate')}
                    tooltip={t('tooltips.productStartDate')}
                    useDayNomenclature
                    disabled={!enableStartDate}
                />
            </>}
            {(!paymentsConfigured || !productSelected) && <>
                <NumberInput
                    name='payment_amount'
                    control={control}
                    label={t('inputs.titles.paymentAmount')}
                    placeHolder="100"
                    prefixText={currency ? currency.value : undefined}
                    // helperText={currency ? `${currency.label} (${currency.value})` : undefined}
                    decimalPlaces={2}
                />
                <SelectInput
                    name='payment_schedule'
                    control={control}
                    label={t('inputs.titles.paymentSchedule')}
                    items={paymentScheduleOptions}
                    allowEmpty
                />
            </>}
            <SelectInput
                name='checkin_template_id'
                control={control}
                label={t('inputs.titles.checkinTemplate')}
                items={checkinTemplates?.filter((c) => !c.draft) ?? []}
                itemLabel="name"
                itemValue="id"
                />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        name='checkin_frequency'
                        control={control}
                        label={t('inputs.titles.checkinFrequency')}
                        items={checkinFrequencyOptions}
                        onChange={() => setValue('checkin_days', [])}
                        />
                </Grid>
                <Grid item xs={6}>
                    {checkinFrequency === 'weekly' ? 
                        <MultiselectInput
                            name='checkin_days'
                            control={control}
                            label={t('inputs.titles.checkinDay')}
                            items={checkinDayOptions}
                            disabled={checkinDisabled}
                            /> :
                        <SelectInput
                            name='checkin_days'
                            control={control}
                            label={t('inputs.titles.checkinDay')}
                            items={checkinDayOptions}
                            disabled={checkinDisabled}
                            />}
                </Grid>
            </Grid>
        </Box>
    );
}

export default ConvertForm;