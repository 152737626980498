import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IProduct } from "@/interfaces/product/product";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { useArchiveProductMutation, useUpdateProductMutation } from "@/repositories/product";

// Components
import { Box } from "@mui/material";
import { Options } from "@/components/menu";
import ArchiveProductModal from "./archive_modal";
import OverflowMenu from "@/components/overflow_menu";


interface _ProductMenuProps {
    row: IProduct;
    onRestore: (isRestoring: number|null) => void;
}

const ProductMenu: FC<_ProductMenuProps> = ({
    row,
    onRestore
}) => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const [open, setOpen] = useState<string|null>(null);
    const [updateProduct, { isLoading: isUpdating }] = useUpdateProductMutation();
    const [archiveProduct, { isLoading: isArchiving }] = useArchiveProductMutation();
    const disableMenu = isUpdating || isArchiving || row.default || (hasTeam && !isTeamOwner);

    const handleMakeDefault = () => {
        const payload = {
            name: row.name,
            description: row.description,
            default: true
        }
        updateProduct({id: row.id, data: payload}).unwrap().then((product) => {
            showToast({
                type: 'success', 
                title: t(`notifications.product.default.title`), 
                message: t(`notifications.product.default.message`, {name: product.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        }).finally(() => onRestore(null));
    }

    const handleArchiveProduct = () => {
        onRestore(!row.active ? row.id : null);
        archiveProduct({id: row.id, active: !row.active}).unwrap().then((product) => {
            showToast({
                type: 'success', 
                title: t(`notifications.product.${product.active ? 'restored' : 'archived'}.title`), 
                message: t(`notifications.product.${product.active ? 'restored' : 'archived'}.message`, {name: product.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        }).finally(() => onRestore(null));
    }

    const options: Options[] = [
        {name: t('components.menuItems.makeDefault'), disabled: disableMenu || !row.active, action: handleMakeDefault},
    ]

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t(`components.menuItems.${row.active ? 'archive' : 'unarchive'}`)}
                disableDelete={disableMenu || (!!row.total_clients && row.total_clients > 0 && row.active)}
                disabled={isArchiving}
                onDelete={() => row.active ? setOpen('archive') : handleArchiveProduct()}
                />
            {open === 'archive' && <ArchiveProductModal
                open={open === 'archive'}
                onClose={() => setOpen(null)}
                isLoading={isArchiving}
                onArchive={handleArchiveProduct}
                />}
            
        </Box>
    )
}

export default ProductMenu;