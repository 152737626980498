import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { selectStorefrontCurrency } from "@/repositories/storefront";
import IBusinessPerformance from "@/interfaces/business_performance/business_performance";

// Styles
import { CaretDown, CaretUp, Information, Subtract } from "@carbon/icons-react";

// Components
import Tooltip from "@/components/tooltip";
import { Box, Skeleton } from "@mui/material";
import MenuButton from "@/components/menu_button";


interface _StatsCardProps {
    performanceData?: IBusinessPerformance;
    period: string;
    isPerformanceLoading: boolean;
    onSetPeriod: (period: string) => void;
}

const StatsCard: FC<_StatsCardProps> = ({
    performanceData,
    period,
    isPerformanceLoading,
    onSetPeriod
}) => {

    const accountCurrency = useSelector((state) => selectStorefrontCurrency(state, undefined));
    const currencySymbol = getCurrencySymbol(accountCurrency?.toUpperCase());

    const calculateVariance = (current?: number, last?: number) => {
        return roundNumber((current ?? 0) - (last ?? 0), 2);
    }

    const getCurrencyText = (amount: number) => {
        const isNegatve = amount < 0;
        return `${isNegatve ? '-' : ''}${currencySymbol}${amount * (isNegatve ? -1 : 1)}`;
    }

    const getPercentageText = (percentage: number) => {
        // const isNegatve = percentage < 0;
        return `${percentage}%`;
    }

    const getCaret = (amount: number) => {
        if (amount > 0) return <CaretUp size="20" color="var(--support-success)" />;
        if (amount < 0) return <CaretDown size="20" color="var(--support-error)" />;
        return <Subtract size="20" />;
        
    }

    const clientsVariation = t('pages.business.performance.statsCard.clients', {count: calculateVariance(performanceData?.current_period_active_subscriptions, performanceData?.last_period_active_subscriptions)});
    const mrrVariation = calculateVariance(performanceData?.current_period_mrr, performanceData?.last_period_mrr);
    const totalRevenueVariation = calculateVariance(performanceData?.current_period_total_revenue, performanceData?.last_period_total_revenue);
    const growthRateVariation = calculateVariance(performanceData?.current_period_growth_rate, performanceData?.last_period_growth_rate);
    const averageLtvVariation = calculateVariance(performanceData?.current_period_ltv, performanceData?.last_period_ltv);
    const retentionRateVariation = calculateVariance(performanceData?.current_period_retention_rate, performanceData?.last_period_retention_rate);


    const translation = 'pages.business.performance.statsCard';
    const stats = [
        {id: 1, title: t(`${translation}.activeSubscribers`), info: t(`${translation}.activeSubscribersInfo`), value: performanceData?.current_period_active_subscriptions, variation: clientsVariation, caret: getCaret(calculateVariance(performanceData?.current_period_active_subscriptions, performanceData?.last_period_active_subscriptions))},
        {id: 2, title: t(`${translation}.mrr`), info: t(`${translation}.mrrInfo`), value: `${currencySymbol}${performanceData?.current_period_mrr}`, variation: getCurrencyText(mrrVariation), caret: getCaret(mrrVariation)},
        {id: 3, title: t(`${translation}.totalRevenue`), info: t(`${translation}.totalRevenueInfo`), value: `${currencySymbol}${performanceData?.current_period_total_revenue}`, variation: getCurrencyText(totalRevenueVariation), caret: getCaret(totalRevenueVariation)},
        {id: 4, title: t(`${translation}.growthRate`), info: t(`${translation}.growthRateInfo`), value: `${performanceData?.current_period_growth_rate}%`, variation: getPercentageText(growthRateVariation), caret: getCaret(growthRateVariation)},
        {id: 5, title: t(`${translation}.averageLtv`), info: t(`${translation}.averageLtvInfo`), value: `${currencySymbol}${performanceData?.current_period_ltv}`, variation: getCurrencyText(averageLtvVariation), caret: getCaret(averageLtvVariation)},
        {id: 6, title: t(`${translation}.retentionRate`), info: t(`${translation}.retentionRateInfo`), value: `${performanceData?.current_period_retention_rate}%`, variation: getPercentageText(retentionRateVariation), caret: getCaret(retentionRateVariation)}
    ];

    const periodOptions = [
        {id: 'month', name: t('timeDate.lastMonth'), action: () => onSetPeriod('month')},
        {id: 'quarter', name: t('timeDate.lastQuarter'), action: () => onSetPeriod('quarter')},
        {id: 'year', name: t('timeDate.lastYear'), action: () => onSetPeriod('year')}
    ]

    return (
        <Box 
            display="flex" 
            flexDirection="column"
            flexGrow={1}
            border="solid 1px var(--border-subtle-01)"
            borderRadius="6px"
            bgcolor="var(--layer-01)"
            >

            {/* Header */}
            <Box display="flex" alignItems="center" padding="8px 24px" borderBottom="solid 1px var(--border-subtle-01)">
                <MenuButton
                    size="small"
                    kind="tertiary"
                    label={periodOptions.find(option => option.id === period)?.name}
                    options={periodOptions}
                    activeOption={period}
                    />
            </Box>

            {/* Stats */}
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr" padding="24px 24px 24px 24px">
                {stats.map((stat) => 
                    <Box key={stat.id} display="flex" flexDirection="column" alignItems="center">
                        <Box display="flex">
                            <span className="heading-07-compact" style={{marginBottom: '8px'}}>{stat.title}</span>
                            <Tooltip kind="nav" size="medium" title={stat.info} placement="top">
                                <Information size="14px" style={{marginLeft: '6px', marginTop: '2px'}} />
                            </Tooltip>
                        </Box>
                        {isPerformanceLoading ?
                            <Skeleton variant="rectangular" width={100} height="28px" sx={{marginBottom: '8px'}} /> :
                                <span className="heading-05" style={{marginBottom: '6px'}}>{stat.value}</span>}
                        {isPerformanceLoading ?
                            <Skeleton variant="rectangular" width={100} height="20px"/> :
                            <Box display="flex" alignItems="center">
                            {stat.caret}
                            <span className="body-02" style={{marginRight: '8px'}}>{stat.variation}</span>
                        </Box>}
                    </Box>
                )}
            </Box>

        </Box>
    );

}

export default StatsCard;