import { FC } from "react";
import { t } from "i18next";

// Components
import { Box } from "@mui/material";


interface _NoPayoutsProps {
    message?: string;
}

const NoPayouts: FC<_NoPayoutsProps> = ({
    message = t('components.dataTable.noData.noPayouts'),
}) => {

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

        </Box>
    )
}

export default NoPayouts;