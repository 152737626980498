import dayjs from "dayjs";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";


interface _OnboardingModalProps {
    open: boolean;
    onClose: () => void;
    name: string;
    plan_start_date?: string;
    hasCheckout: boolean;
    isLoading: boolean;
    onSubmit: () => void;
}

const OnboardingModal: FC<_OnboardingModalProps> = ({
    open,
    onClose,
    name,
    plan_start_date,
    hasCheckout,
    isLoading,
    onSubmit
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.onboardClient.title')}
            text={<Trans i18nKey="modals.onboardClient.text" values={{name: name, date: dayjs(plan_start_date).format('D MMMM YYYY'), context: `${hasCheckout ? false : true}`}} />}
            action1={{
                label: t('components.buttons.onboardClient'),
                loading: isLoading,
                onClick: onSubmit
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
    )
}

export default OnboardingModal;