import { t } from "i18next";
import { useSelector } from "react-redux";

// Services and interfaces
import { NavItem } from "@/interfaces/components/nav_item";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { selectPaymentsEnabled } from "@/store/subscription";
import { selectPaymentGatewayConfigured, useGetStorefrontQuery } from "@/repositories/storefront";

// Styles
import { 
    AlignBoxTopLeft, 
    Analytics, 
    Book, 
    Catalog, 
    Currency, 
    FileStorage, 
    Finance, 
    Fish, 
    List, 
    ListCheckedMirror, 
    Notebook, 
    Pills, 
    Restaurant, 
    Running, 
    Template, 
    User, 
    UserServiceDesk 
} from "@carbon/icons-react";


const useNavigationItems = (): Array<NavItem> => {

    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const paymentsEnabled = useSelector(selectPaymentsEnabled);
    useGetStorefrontQuery(undefined, {skip: !paymentsEnabled});
    const showTeamPayments = !hasTeam || (hasTeam && isTeamOwner);
    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));

    const NavItems: Array<NavItem> = [
        {
            "label": t('navigation.clients'),
            "startIcon": <User />,
            "path": "/clients/active",
            "tabsRoot": "/clients"
        },
        {
            "label": t('navigation.prospects'),
            "startIcon": <UserServiceDesk />,
            "path": "/prospects/list",
            "tabsRoot": "/prospects"
        },
        {
            "label": t('navigation.checkins'),
            "startIcon": <List />,
            "path": "/checkins"
        },
        {
            "label": t('navigation.vault'),
            "startIcon": <FileStorage />,
            "path": "/vault/folders",
            "tabsRoot": "/vault"
        },
        {
            "label": t('navigation.templates'),
            "startIcon": <Template />,
            "path": "/templates",
            "children" : [
                {
                    "label": t('navigation.checkins'),
                    "startIcon": <ListCheckedMirror />,
                    "path": hasTeam ? "/templates/checkins/personal" : "/templates/checkins",
                    "tabsRoot": "/templates/checkins"
                },
                {
                    "label": t('navigation.programmes'),
                    "startIcon": <AlignBoxTopLeft />,
                    "path": hasTeam ? "/templates/programmes/personal" : "/templates/programmes",
                    "tabsRoot": "/templates/programmes"
                },
                {
                    "label": t('navigation.nutritionPlans'),
                    "startIcon": <Restaurant />,
                    "path": hasTeam ? "/templates/nutrition/personal" : "/templates/nutrition",
                    "tabsRoot": "/templates/nutrition"
                },
                {
                    "label": t('navigation.supplementPlans'),
                    "startIcon": <Pills />,
                    "path": hasTeam ? "/templates/supplements/personal" : "/templates/supplements",
                    "tabsRoot": "/templates/supplements"
                },
                {
                    "label": t('navigation.activityPlans'),
                    "startIcon": <Running />,
                    "path": hasTeam ? "/templates/activity/personal" : "/templates/activity",
                    "tabsRoot": "/templates/activity"
                },
            ]
        },
        {
            "label": t('navigation.catalogues'),
            "startIcon": <Catalog />,
            "path": "/catalogues",
            "children" : [
                {
                    "label": t('navigation.workouts'),
                    "startIcon": <Book />,
                    "path": "/catalogues/workouts",
                    "tabsRoot": "/catalogues/workouts"
                }
            ]
        },
        {
            "label": t('navigation.library'),
            "startIcon": <Notebook />,
            "path": "/library",
            "children" : [
                {
                    "label": t('navigation.exercises'),
                    "startIcon": <Running />,
                    "path": "/library/exercises"
                },
                {
                    "label": t('navigation.foods'),
                    "startIcon": <Fish />,
                    "path": "/library/foods"
                },
                {
                    "label": t('navigation.supplements'),
                    "startIcon": <Pills />,
                    "path": "/library/supplements"
                }
            ]
        },
        ...(!paymentsEnabled ? [{
            "label": "Business",
            "startIcon": <Currency />,
            "path": "/business/setup"
        }] : []),
        ...(paymentsEnabled && !paymentsConfigured && showTeamPayments ? [{
            "label": "Business",
            "startIcon": <Currency />,
            "path": "/settings/1fitpay",
            "replace": true
        }] : []),
        ...(paymentsEnabled && paymentsConfigured ? [{
            "label": t('navigation.business'),
            "startIcon": <Currency />,
            "path": "/business",
            "children" : [
                {
                    "label": t('navigation.payments'),
                    "startIcon": <Finance />,
                    "path": "/business/payments/products",
                    "tabsRoot": "/business/payments"
                },
                ...(isTeamOwner || !hasTeam ? [{
                    "label": t('navigation.performance'),
                    "startIcon": <Analytics />,
                    "path": "/business/performance"
                }] : [])
            ]
        }] : [])
    ]

    return NavItems;
}

export default useNavigationItems;