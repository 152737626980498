import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITemplateAssignForm } from "@/interfaces/template/assign_form";
import { ISupplementPlan } from "@/interfaces/supplement_plan/supplement_plan";
import { selectIsClientReadonly, useGetClientQuery } from "@/repositories/client";
import { ISupplementBuilder } from "@/interfaces/supplement_plan/supplement_builder";
import { useAssignSupplementTemplateMutation, useGetSupplementTemplatesQuery } from "@/repositories/supplement_template";
import { useAddSupplementPlanMutation, useDeleteSupplementPlansMutation, useGetSupplementPlansQuery } from "@/repositories/supplement_plan";

// Components
import NoPlans from "./no_plans";
import DataTable from "@/components/datatable";
import { Box, Container } from "@mui/material";
import PlanMenu from "@/pages/client/components/plan_menu";
import useSupplementPlanTableHeaders from "./table_headers";
import SecondaryToolbar from "@/components/secondary_toolbar";
import AssignModal from "@/pages/client/components/assign_modal";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import DeletePlanModal from "@/pages/client/components/delete_modal";
import SupplementModal from "@/components/supplement_builder/supplement_modal";


const SupplementPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const readonly = useSelector((state) => selectIsClientReadonly(state, Number(id)));
    const headers = useSupplementPlanTableHeaders();
    const { data: client } = useGetClientQuery(Number(id));
    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const { data: list, isLoading } = useGetSupplementPlansQuery(Number(id));
    const [addSupplementPlan, { isLoading: isAdding }] = useAddSupplementPlanMutation();
    const [assignSupplementTemplate, { isLoading: isAssigning }] = useAssignSupplementTemplateMutation();
    const { data: templates, isLoading: isTemplatesLoading } = useGetSupplementTemplatesQuery();
    const [deletePlans, { isLoading: isDeletingMany }] = useDeleteSupplementPlansMutation();

    useEffect(() => {
        if (!client?.coach_settings.supplementation.supplements) navigate(`/clients/${id}/overview`);
    }, [id, client?.coach_settings.supplementation.supplements, navigate]);

    const handleOpenPlan = (plan: ISupplementPlan) => {
        if (showBulkActions) return;
        navigate(`/clients/${id}/supplements/${plan.id}`);
    }

    const handleAdd = (data: ISupplementBuilder) => {
        addSupplementPlan({id: Number(id), data: data as ISupplementPlan}).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.created.title'), 
                message: t('notifications.plan.created.message', {name: plan.name})
            });
            setOpen(null);
            navigate(`/clients/${id}/supplements/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleAssign = (data: ITemplateAssignForm) => {
        assignSupplementTemplate(data).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.assigned.title', { count: 1 }), 
            });
            setOpen(null);
            navigate(`/clients/${id}/supplements/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDeleteMany = () => {
        deletePlans({id: Number(id), plans: selected}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.plan.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box width='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.plans.supplement.title')}
                slot={<PlanMenu label={t('components.buttons.addSupplementPlan')} disabled={readonly} setOpen={setOpen} />}
                />
                {open === 'add' && <SupplementModal 
                    open={open == 'add'}
                    onClose={() => setOpen(null)}
                    isLoading={isAdding}
                    onSave={handleAdd}
                />}
                {open === 'assign' && <AssignModal 
                    open={open === 'assign'} 
                    onClose={() => setOpen(null)}
                    client_id={Number(id)}
                    templates={templates?.filter((t) => !t.draft) ?? [] ?? []}
                    isLoading={isAssigning}
                    isTemplatesLoading={isTemplatesLoading}
                    onSave={handleAssign}
                    />}

            <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
            <Container sx={{py: '24px'}}>

                <DataTable
                    data={list}
                    dataKey="id"
                    columns={headers}
                    filter={{showSelect: !readonly}}
                    filterKeys={["name"]}
                    bulkActions={{
                        disableSelect: (plan: ISupplementPlan) => plan.active,
                        action1: {
                            label: t('components.buttons.delete'), 
                            onClick: () => setOpen('deleteMany'),
                        },
                    }}
                    hideHeader
                    localPagination 
                    noDataMessage={<NoPlans message={t('components.dataTable.noData.noPlans')} disabled={readonly} />}
                    noDataFoundMessage={<NoPlans message={t('components.dataTable.noData.foundDefault')} disabled={readonly} />}
                    isLoading={isLoading}
                    rowClick={handleOpenPlan}
                />
                {open === 'deleteMany' && <DeletePlanModal
                    open={open == 'deleteMany'}
                    onClose={()=> setOpen(null)}
                    name=''
                    count={selected.length}
                    isLoading={isDeletingMany}
                    onDelete={handleDeleteMany}
                />}

            </Container>
            </Box>

        </Box>
    )
}

export default SupplementPage