import dayjs from "dayjs";
import { FC, useState } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";

// Services and interfaces
import { RequirementStatus } from "@/interfaces/storefront/storefront";

// Components
import { InlineNotification } from "@/components/notification/notification"
import ManageAccountModal from "./manage_account_modal";


interface _PersonalIdRequiredProps {
    requirement: string;
    status: RequirementStatus;
    current_deadline?: string;
}

const AccountRequirementRequired: FC<_PersonalIdRequiredProps> = ({
    requirement,
    status,
    current_deadline
}) => {

    const [open, setOpen] = useState(false);
    const hasAction = status !== 'pending_verification';
    const date = current_deadline && new Date(current_deadline).toLocaleDateString();
    const hasDatePassed = dayjs(date).isBefore(dayjs(), 'day');

    const getSeverity = () => {
        if (!status) return 'info';
        if (['eventually_due'].includes(status)) return 'info';
        if (['currently_due', 'alternative'].includes(status)) return 'warning';
        if (['past_due', 'error'].includes(status)) return 'error';
        return 'success';
    }

    const getTranslation = (): string => {
        if (['eventually_due'].includes(status)) return 'required';
        if (['currently_due', 'alternative'].includes(status)) return 'due';
        if (['past_due', 'error'].includes(status)) return 'overdue';
        if (status === 'pending_verification') return 'pending';
        return 'required';
    }

    const openAccount = () => {
        setOpen(true);
        // window.open('https://dashboard.stripe.com/account/status', '_blank');
    }

    if (!status) return null;

    return (
        <>
            <InlineNotification
                type={getSeverity()}
                title={t(`inlineNotifications.1fitpay.${requirement}.${getTranslation()}.title`)}
                message={<Trans
                    i18nKey={`inlineNotifications.1fitpay.${requirement}.${getTranslation()}.message`}
                    values={{
                        context: `${hasDatePassed}`,
                        date: date
                    }}
                    components={{ strong: <strong /> }}
                    />}
                action={hasAction ? {
                    label: t(`components.buttons.${requirement == 'personal_id' ? 'uploadDocuments' : 'verifyAccountInformation'}`),
                    onClick: openAccount
                } : undefined}
                />
                {open && <ManageAccountModal open={open} onClose={() => setOpen(false)} />}

        </>
    )
}

export default AccountRequirementRequired;