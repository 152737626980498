import dayjs from "dayjs";
import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import IClient from "@/interfaces/client/client";
import { selectCurrency } from "@/store/settings";
import { selectPaymentGatewayConfigured } from "@/repositories/storefront";

// Styles
import { Add, DotMark } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Link from "@/components/link";
import ClientMenu from "./client_menu";
import Avatar from "@/components/avatar";
import { ColDef } from "@/components/datatable";
import AddProductModal from "@/pages/client/components/subscription/add_product_modal";


const useClientTableHeaders = (): ColDef<IClient>[] => {

    const { t } = useTranslation();
    const path = window.location.pathname;
    const hidePaymentPlan = path.includes('inactive');
    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));

    const headers: ColDef<IClient>[] = [
        {
            field: 'full_name',
            headerName: t('components.dataTable.headers.name'),
            width: '20%',
            sortable: true,
            render: (row, i) => <Box display="flex" alignItems="center">
                <Avatar index={i} name={row.full_name} src={row.avatar} />
                <Box ml="12px">
                    {capitalize(row.full_name)}
                </Box>
            </Box>
        },
        {
            field: 'email',
            headerName: t('components.dataTable.headers.email'),
            width: '25%',
            render: (row) => <span className="OneLine--ellipsis-breakWord">{row.email}</span>
        },
        {
            field: 'next_checkin',
            headerName: t('components.dataTable.headers.nextCheckin'),
            width: '10%',
            render: (row) => dayjs(row.next_checkin).format('DD/MM/YYYY')
        },
        {
            field: 'weeks_as_client',
            headerName: t('components.dataTable.headers.weeksAsClient'),
            width: '10%',
            render: (row) => `${Math.ceil(row.weeks_as_client)} weeks`
        },
        ...(!hidePaymentPlan ? [{
            field: 'payment_schedule',
            headerName: t('components.dataTable.headers.paymentPlan'),
            width: '15%',
            render: (row: IClient) => paymentsConfigured ? 
                <_ProductCell client={row} />
                : (row.payment_schedule ? <_PaymentCell amount={row.payment_amount} schedule={row.payment_schedule} /> : '-')
        }] : []),
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IClient) => <ClientMenu row={row} />
        }
    ]

    return headers;
}

export default useClientTableHeaders;

interface _PaymentCellProps {
    amount: number;
    schedule: string;
}

const _PaymentCell: FC<_PaymentCellProps> = ({
    amount,
    schedule
}) => {

    const currency = useSelector(selectCurrency);
    const scheduleText = t(`enums.paymentSchedule.${schedule}`);

    return (
        <Box display="flex" flexDirection="column" ml="8px">
            <span className="label-text-02 OneLine--ellipsis-breakWord">{t('pages.client.offlinePayment')}</span>
            <span className="label-text-03">{`${currency?.value}${amount} - ${scheduleText}`}</span>
        </Box>
    )
}

interface _ProductCellProps {
    client: IClient;
}

const _ProductCell: FC<_ProductCellProps> = ({
    client
}) => {

    const product = client.product;
    const [open, setOpen] = useState<string | null>(null);

    const getStatusColour = (status: string) => {
        switch (status) {
            case 'active':
                return 'var(--support-success)';
            case 'pending':
            case 'provisional':
            case 'scheduled':
            case 'cancellation_pending':
                return 'var(--support-warning)';
            case 'past_due':
            case 'cancelled':
                return 'var(--support-error)';
            default:
                return 'grey';
        }
    }

    if (!product) return (
        <>
        <Link
            size="small"
            label="Add product"
            icon={<Add />}
            onClick={() => setOpen('add_product')}
            />
            {open === 'add_product' && <AddProductModal
                open={open === 'add_product'}
                onClose={() => setOpen(null)}
                client_id={client.id}
                client_name={client.full_name}
                />}
        </>
    );

    return (
        <Box display="flex" alignItems="center">
            <DotMark size="18" color={getStatusColour(product.status)} />
            <Box display="flex" flexDirection="column" ml="8px">
                <span className="label-text-02 OneLine--ellipsis-breakWord">{product.product_name}</span>
                <span className="label-text-03">{t(`enums.productSubscriptionStatus.${product.status}`)}</span>
            </Box>
        </Box>
    )
}