import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { selectMembers } from "@/store/team";

// Components
import { FilterItem } from "@/components/datatable/filter/filter_bar";


const useTransactionFilterItems = (): FilterItem[] => {

    const { t } = useTranslation();
    const memberNames = useSelector(selectMembers).map((member) => {
        return {
            id: member.id,
            label: member.name,
            value: member.name
        }
    })

    const filters: FilterItem[] = [
        {
            type: 'primary',
            label: t('components.dataTable.headers.coach'),
            field: 'coach_name',
            items: memberNames,
            itemKey: 'value'
        }
    ];

    return filters;
}

export default useTransactionFilterItems;