import { t } from "i18next";
import { FC, useState } from "react";

// Helpers
import { formatAmount } from "@/_helpers/number_functions";
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { IRefund, ITransaction } from "@/interfaces/transaction/transaction";

// Styles
import { ArrowUpRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import FeesModal from "@/components/payments/fees_modal";


interface _DetailsModalProps {
    open: boolean;
    onClose: () => void;
    transaction: ITransaction;
}

const DetailsModal: FC<_DetailsModalProps> = ({
    open,
    onClose,
    transaction,
}) => {

    const [openFees, setOpenFees] = useState<boolean>(false);
    const hasRefunds = transaction.refunds.length > 0;
    const refundTotal = transaction.refunds.reduce((acc, refund) => acc + refund.amount, 0);
    const providerFees = transaction.fees.find(fee => fee.type === 'provider_fees');
    const platformFees = transaction.fees.find(fee => fee.type === 'platform_fees');
    const netAmount = formatAmount(transaction.amount - (refundTotal ?? 0) - (providerFees?.amount ?? 0) - (platformFees?.amount ?? 0));

    const getLineItem = (label: string, value: string, hideBorder?: boolean, bold?: boolean) => {
        return (
            <Box display="flex" justifyContent="space-between" py="8px" style={{borderBottom: hideBorder ? 'unset' : 'solid 1px var(--border-subtle-01)'}}>
                <span className={`label-text-02${bold ? '-semibold' : ''}`} style={{color: 'var(--text-secondary)'}}>{label}</span>
                <span className={`label-text-02${bold ? '-semibold' : ''}`} style={{color: 'var(--text-secondary)'}}>{value}</span>
            </Box>
        )
    }

    const getRefundItem = (refund: IRefund, hideBorder?: boolean) => {
        return (
            <Box display="flex" justifyContent="space-between" py="8px" style={{borderBottom: hideBorder ? 'unset' : 'solid 1px var(--border-subtle-01)'}}>
                <span className={'label-text-03'} style={{color: 'var(--text-secondary)', marginLeft: '16px'}}>{t('modals.transactionDetails.list.refundDetail', {name: refund.refunded_by, date: new Date(refund.refunded_at).toLocaleDateString()})}</span>
                <span className={'label-text-03'} style={{color: 'var(--text-secondary)'}}>{`-${getCurrencySymbol(refund.currency)}${refund.amount}`}</span>
            </Box>
        )
    }

    return (
        <>
        <Modal 
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.transactionDetails.title')}
            text={t('modals.transactionDetails.text')}
            children={
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" width="100%">
                        {getLineItem(t('modals.transactionDetails.list.datePaid'), new Date(transaction.paid_at).toLocaleDateString())}
                        {getLineItem(t('modals.transactionDetails.list.paymentAmount'), `${getCurrencySymbol(transaction.currency)}${transaction.amount}`)}
                        {hasRefunds && getLineItem(t('modals.transactionDetails.list.refundAmount'), `-${getCurrencySymbol(transaction.currency)}${formatAmount(refundTotal)}`, true)}
                        {hasRefunds && transaction.refunds.map((refund, index) => getRefundItem(refund, index !== transaction.refunds.length - 1))}
                        {providerFees && getLineItem(t('modals.transactionDetails.list.providerFees'), `-${getCurrencySymbol(providerFees?.currency)}${providerFees?.amount}`)}
                        {platformFees && getLineItem(t('modals.transactionDetails.list.platformFees'), `-${getCurrencySymbol(platformFees?.currency)}${platformFees?.amount}`)}
                        {getLineItem(t('modals.transactionDetails.list.net'), `${getCurrencySymbol(transaction.currency)}${netAmount}`, true, true)}
                    </Box>
                </Box>
            }
            action1={{
                kind: "ghost",
                label: t('components.buttons.viewFees'),
                icon: <ArrowUpRight />,
                loading: false,
                onClick: () => setOpenFees(true),
                sx: {minWidth: 'fit-content'}
            }}
            sx={{'& .MuiPaper-root': {width: '100%', maxWidth: '480px', background: 'var(--background)'}}}
            />
            {openFees && <FeesModal
                open={openFees}
                onClose={() => setOpenFees(false)}
                />}
        </>
    )
}

export default DetailsModal;