import { t } from "i18next";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useGetStorefrontQuery, useUpdateStorefrontMutation } from "@/repositories/storefront";
import IStorefrontProfileForm, { storefrontProfileFormSchema } from "@/interfaces/storefront/storefront_profile_form";

// Styles
// import { ImageSearch } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
// import Link from "@/components/link";
import Button from "@/components/button";
import TextInput from "@/components/text_input";
import { zodResolver } from "@hookform/resolvers/zod";
import ColourPicker from "@/components/colour_picker";
import AvatarUploader from "@/components/avatar_uploader/avatar_uploader";


const StorefrontProfile: FC = () => {

    const {data: storefront, isLoading: isLoadingStorefront } = useGetStorefrontQuery();
    const [updateStorefront, { isLoading: isUpdatingStorefront }] = useUpdateStorefrontMutation();

    const { control, reset, handleSubmit, formState} = useForm<IStorefrontProfileForm>({
        resolver: zodResolver(storefrontProfileFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            description: '',
            primary_color: '',
            secondary_color: '',
            store_logo: ''
        }
    });

    useEffect(() => {
        if (storefront) {
            reset({
                name: storefront.name ?? '',
                description: storefront.description ?? '',
                primary_color: storefront.primary_color ?? '#1D212D',
                secondary_color: storefront.secondary_color ?? '#2CF5B8',
                store_logo: storefront.store_logo ?? ''
            });
        }
    }, [storefront, reset]);

    const handleUpdateStorefront = async (data: IStorefrontProfileForm) => {
        if (!storefront) return;

        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'store_logo') return;
            formData.append(key, value);
        });

        if (formState.dirtyFields.store_logo && data.store_logo) {
            const blob = await fetch(data.store_logo as URL).then(r => r.blob());
            formData.append('store_logo', blob, 'store_logo.jpg');
        }

        updateStorefront({id: storefront.id, data: formData}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.storefront.updated.title'), 
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            rowGap="24px"
            alignItems="flex-start" 
            textAlign="left" 
            padding="24px 32px" 
            borderRadius="6px" 
            border="1px solid var(--border-subtle-01)" 
            bgcolor="var(--layer-01)"
            >
            
            <Box display="flex" flexDirection="column">
                <span className="heading-05" style={{marginBottom: '12px'}}>{t('pages.settings.storefront.profile.title')}</span>
                <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t('pages.settings.storefront.profile.text')}</span>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="clamp(24px, 5vw, 100px)">

                <Box display="flex" flexDirection="column" rowGap="12px">

                    <TextInput
                        name='name'
                        control={control}
                        label={t('inputs.titles.storefrontName')}
                        />
                    <TextInput
                        name='description'
                        control={control}
                        label={t('inputs.titles.storefrontDescription')}

                        />

                    <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="12px">
                        <ColourPicker
                            name='primary_color'
                            control={control}
                            label={t('components.colourPicker.titles.primaryColour')}
                            isLoading={isLoadingStorefront}
                            />
                        <ColourPicker
                            name='secondary_color'
                            control={control}
                            label={t('components.colourPicker.titles.accentColour')}
                            isLoading={isLoadingStorefront}
                            />
                    </Box>

                </Box>

                <AvatarUploader 
                    name='store_logo' 
                    control={control} 
                    label={t('components.avatarUploader.storefrontLogo')} 
                    isDeleting={false} 
                    onDelete={() => {}} 
                    />
            </Box>

            <Box display="flex" width="100%" justifyContent="flex-end">
                {/* <Link
                    label={t('components.links.viewPreviews')}
                    icon={<ImageSearch />}
                    /> */}
                <Button
                    size="small"
                    label={t('components.buttons.save')}
                    loading={isUpdatingStorefront}
                    onClick={handleSubmit(handleUpdateStorefront)}
                    />
            </Box>

        </Box>
    )
}

export default StorefrontProfile;