import dayjs, { Dayjs } from "dayjs";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Styles
import '@carbon/charts-react/styles.css';

// Components
import { Box } from "@mui/material";
import MenuButton from "@/components/menu_button";
import { LineChart as CarbonLineChart, ScaleTypes, SimpleBarChart } from '@carbon/charts-react';
import { snakeToCapitalizedWords } from "@/_helpers/text_functions";


interface _ChartObject {
    group: string;
    key: Date;
    value: number;
}
interface _ChartData {
    date: string;
    [key: string]: any;
}

interface _LineChartProps<T extends _ChartData> {
    kind?: 'line' | 'bar';
    data: T[];
    metric: string;
    isRating?: boolean;
    yLabel?: string;
    xLabel?: string;
}

const LineChart = <T extends _ChartData>({
    kind = 'line',
    data,
    metric,
    isRating = false,
    yLabel,
    xLabel
}: _LineChartProps<T>) => {

    const { t } = useTranslation();

    const now = dayjs();
    const sevenDaysAgo = now.subtract(7, 'day');
    const forteenDaysAgo = now.subtract(14, 'day');
    const thirtyDaysAgo = now.subtract(30, 'day');
    const ninetyDaysAgo = now.subtract(90, 'day');
    const oneHundredAndEightyDaysAgo = now.subtract(180, 'day');
    const allTime = dayjs(data[data.length-1].date);
    const [selectedRange, setSelectedRange] = useState<{name: string, range: Dayjs}>({name: t('components.charts.rangeOptions.last7Days'), range: sevenDaysAgo});

    const rangeOptions = [
        {name: t('components.charts.rangeOptions.last7Days'), action: () => setSelectedRange({name: t('components.charts.rangeOptions.last7Days'), range: sevenDaysAgo})},
        {name: t('components.charts.rangeOptions.last14Days'), action: () => setSelectedRange({name: t('components.charts.rangeOptions.last14Days'), range: forteenDaysAgo})},
        {name: t('components.charts.rangeOptions.last30Days'), action: () => setSelectedRange({name: t('components.charts.rangeOptions.last30Days'), range: thirtyDaysAgo})},
        {name: t('components.charts.rangeOptions.last90Days'), action: () => setSelectedRange({name: t('components.charts.rangeOptions.last90Days'), range: ninetyDaysAgo})},
        {name: t('components.charts.rangeOptions.last180Days'), action: () => setSelectedRange({name: t('components.charts.rangeOptions.last180Days'), range: oneHundredAndEightyDaysAgo})}, 
        {name: t('components.charts.rangeOptions.allTime'), action: () => setSelectedRange({name: t('components.charts.rangeOptions.allTime'), range: allTime})},
    ];

    const emptyData = [{ group: snakeToCapitalizedWords(metric), key: now, value: 0 }, { group: snakeToCapitalizedWords(metric), key: now.subtract(1, 'day'), value: 0 }]
      
    const chartData = useMemo(() => {
        const d: _ChartObject[] = [];
        data.filter((item) => item[metric] && dayjs(item.date).isAfter(selectedRange.range))
            .sort((a, b) => dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1)
                .map((item) => {
                    if (typeof item[metric] === 'object') {
                        Object.entries(item[metric]).forEach(([subMetricKey, subMetricValue]) => {
                            d.push({
                                group: snakeToCapitalizedWords(subMetricKey),
                                key: new Date(item.date),
                                value: subMetricValue as number,
                            });
                        });
                        return;
                    }
                    d.push({
                        group: snakeToCapitalizedWords(metric),
                        key: new Date(item.date),
                        value: item[metric],
                    });
                });
    
        return d;
    }, [data, metric, selectedRange]);

    const noData = chartData.length <= 1;

    // Calculate the min and max
    const minValue = Math.min(...chartData.map(d => d.value));
    const maxValue = Math.max(...chartData.map(d => d.value));

    // Round down the min and round up the max to the nearest 10
    const roundedMin = Math.floor(minValue / 10) * 10;
    const roundedMax = Math.ceil(maxValue / 10) * 10;

    const colorScale = (): Record<string, string> => {
        const colors = [
            '#6929c4', // Primary purple
            '#1192e8', // Secondary blue
            '#005d5d', // Tertiary teal
        ];
        
        const colorScale: Record<string, string> = {};
        const groups = Array.from(
            new Set(chartData.map(item => item.group))
        ).sort();
        
        groups.map((group, index) => {
            colorScale[group] = colors[index];
        });
        
        return colorScale;
    };

    const barWidth = () => {
        switch (selectedRange.name) {
            case t('components.charts.rangeOptions.last7Days'):
                return 10;
            case t('components.charts.rangeOptions.last14Days'):
                return 8;
            case t('components.charts.rangeOptions.last30Days'):
                return 6;
            case t('components.charts.rangeOptions.last90Days'):
                return 4;
            case t('components.charts.rangeOptions.last180Days'):
                return 2;
            case t('components.charts.rangeOptions.allTime'):
                return 1;
            default:
                return 2;
        }
    }

    const options = {
        title: '',
        axes: {
            bottom: {
                title: xLabel ?? '',
                mapsTo: 'key',
                scaleType: ScaleTypes.TIME,
            },
            left: {
                title: yLabel ?? '',
                mapsTo: 'value',
                scaleType: ScaleTypes.LINEAR,
                domain: isRating ? [1, 5] : [roundedMin, roundedMax],
                ticks: {
                    values: isRating ? [1, 2, 3, 4, 5] : undefined
                }
            }
        },
        bars: {
            width: barWidth()
        },
        curve: 'curveMonotoneX',
        toolbar: { enabled: false },
        legend: { enabled: true },  // Show legend to distinguish between the lines
        grid: {
            x: { enabled: true },
            y: { enabled: true }
        },
        color: {
            scale: colorScale()
        },
        height: "90%",
    }

    return (
        <Box display="flex" flexDirection="column" width="90vw" height="80vh" padding="16px 32px" boxSizing="border-box" borderRadius="6px" border="solid 1px var(--border-subtle-01)" sx={{bgcolor: 'var(--layer-01)'}}>
            <Box display="flex" justifyContent="flex-end" width="100%" padding="8px 0">
                <MenuButton
                    kind="tertiary"
                    size="small"
                    label={rangeOptions.find((option) => option.name === selectedRange.name)?.name}
                    options={rangeOptions}
                    />
            </Box>
            {kind == 'line' && <CarbonLineChart 
                data={!noData ? chartData : emptyData}
                options={options}
                />}
            {kind == 'bar' && <SimpleBarChart 
                data={!noData ? chartData : emptyData}
                options={options}
                />}

            {noData && (
                <_NoData />
            )}
        </Box>
    )
}

export default LineChart;

const _NoData: FC = () => {

    const { t } = useTranslation();

    return (
        <Box 
            sx={{
                position: 'relative',
                bottom: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '22px',
                marginRight: '2px',
                opacity: 0.7,
                backgroundColor: 'var(--layer-01)', // Slightly opaque background
                backdropFilter: 'blur(4px)', // Apply blur effect to overlay
                zIndex: 2, // Ensure the overlay is on top of the chart
            }}>
            <span className="body-02" style={{color: 'var(--text-secondary)'}}>{t('components.charts.insufficientData')}</span>
        </Box>
    )
};