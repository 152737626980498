import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectClientMetrics, selectIsClientReadonly } from "@/repositories/client";
import { IProgrammePlan } from "@/interfaces/programme/programme_plan";
import { ITemplateAssignForm } from "@/interfaces/template/assign_form";
import { useAddProgrammePlanMutation } from "@/repositories/programme_plan";
import { IProgrammeBuilder } from "@/interfaces/programme/programme_builder";
import { useAssignProgrammeTemplateMutation, useGetProgrammeTemplatesQuery } from "@/repositories/programme_template";

// Styles
// import { Edit } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import { TabProps } from "@/components/tabs";
import Container from "@mui/material/Container";
import PlanMenu from "@/pages/client/components/plan_menu";
import SecondaryToolbar from "@/components/secondary_toolbar";
import AssignModal from "@/pages/client/components/assign_modal";
import ProgrammeModal from "@/components/programme_builder/programme_modal";

 
const TrainingPage: FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const readonly = useSelector((state) => selectIsClientReadonly(state, Number(id)));
    console.log(readonly)
    const clientMetrics = useSelector(state => selectClientMetrics(state, Number(id)));
    const [addProgramme, { isLoading: isSaving }] = useAddProgrammePlanMutation();
    const [assignProgrammeTemplate, { isLoading: isAssigning }] = useAssignProgrammeTemplateMutation();

    const { data: templates, isLoading: isTemplatesLoading } = useGetProgrammeTemplatesQuery();

    const tabs: TabProps[] = [
        {
            label: t('pages.client.training'),
            link: `/clients/${id}/training/data`,
            sx: {width: '120px'}
        },
        {
            label: t('tabs.plans'),
            link: `/clients/${id}/training/plans`,
            sx: {width: '120px'}
        }
    ];

    const handleAdd = (data: IProgrammeBuilder) => {
        addProgramme({id: Number(id), data: data as IProgrammePlan}).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.created.title'), 
                message: t('notifications.plan.created.message', {name: plan.name})
            });
            setOpen(null);
            navigate(`/clients/${id}/programmes/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleAssign = (data: ITemplateAssignForm) => {
        assignProgrammeTemplate(data).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.assigned.title', { count: 1 }), 
            });
            setOpen(null);
            navigate(`/clients/${id}/programmes/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar 
                title={t('pages.client.training')} 
                kind="primary"
                tabs={tabs}
                slot={<PlanMenu label={t('components.buttons.addTrainingPlan')} disabled={readonly} setOpen={setOpen} />}
            />
            {open === 'add' && <ProgrammeModal 
                open={open === 'add'} 
                onClose={() => setOpen(null)}
                isLoading={isSaving}
                onSave={handleAdd} 
                defaultMetrics={clientMetrics}
            />}
            {open === 'assign' && <AssignModal 
                open={open === 'assign'} 
                onClose={() => setOpen(null)}
                client_id={Number(id)}
                templates={templates?.filter((t) => !t.draft) ?? []}
                isLoading={isAssigning}
                isTemplatesLoading={isTemplatesLoading}
                onSave={handleAssign}
            />}

            <Container sx={{py: '24px', overflowY: 'auto', height: 'calc(100% - 48px)'}}>
                <Outlet />
            </Container>
        </Box>  
    );
}

export default TrainingPage;