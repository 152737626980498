import dayjs from "dayjs";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { useGetProductsQuery } from "@/repositories/product";
import { IClientProductForm } from "@/interfaces/client/client_product";

// Components
import { Box } from "@mui/material";
import DateInput from "@/components/date_input";
import RadioGroup from "@/components/radio_group";
import SelectInput from "@/components/select_input";
import { InlineNotification } from "@/components/notification/notification";
import { Trans } from "react-i18next";


const AddProductForm: FC = () => {

    const { control, getValues, setValue, watch } = useFormContext<IClientProductForm>();
    const [productStart, setProductStart] = useState<'now'|'fixed_date'>('now');
    const disableStartDate = productStart === 'now';
    const paymentStart = watch('payment_start');

    const { data: products } = useGetProductsQuery();
    const activeProducts = products?.filter((product) => product.active) ?? [];
    const defaultProduct = products?.find(p => p.default);

    useEffect(() => {
        if (getValues('payment_product') === '') {
            setValue('payment_product', defaultProduct?.id ?? '');
        }
    }, [defaultProduct, getValues, setValue])

    const handleEnableStartDate = (value: string) => {
        if (value === 'now') {
            setProductStart('now');
            setValue('payment_start', '');
        } else {
            setProductStart('fixed_date');
            setValue('payment_start', dayjs().format('YYYY-MM-DDTHH:mm:ss'));
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            <SelectInput
                name='payment_product'
                control={control}
                label={t('inputs.titles.product')}
                items={activeProducts}
                itemLabel="name"
                itemKey="id"
                itemValue="id"
                />
            <RadioGroup
                row
                label={t('components.radioGroup.titles.productStarts')}
                defaultValue="now"
                items={[
                    { value: 'now', label: t('components.radioGroup.options.uponPayment') },
                    { value: 'fixed', label: t('components.radioGroup.options.fixedDate') },
                ]}
                onChange={handleEnableStartDate}
                />
            <DateInput
                name='payment_start'
                control={control}
                label={t('inputs.titles.startDate')}
                tooltip={t('tooltips.productStartDate')}
                toLocal
                useDayNomenclature
                disabled={disableStartDate}
                />
            <InlineNotification
                type="info"
                title=""
                message={<Trans
                    i18nKey="inlineNotifications.client.addProduct.message"
                    values={{ 
                        context: productStart == 'now' ? 'true' : 'false',
                        date: dayjs(paymentStart).format('DD/MM/YYYY') 
                    }}
                    components={{ strong: <strong />}}
                    />}
                />
        </Box>
    )
}

export default AddProductForm;