import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { useGetTransactionsQuery } from "@/repositories/transaction";

// Components
import DataTable from "@/components/datatable";
import { Container, Box } from "@mui/material";
import useTransactionFilterItems from "./filter_items";
import useTransactionTableHeaders from "./table_headers";
import NoTransactions from "@/components/transactions/no_transactions";


const TransactionsTab: FC = () => {

    const hasTeam = useSelector(selectHasTeam);
    const headers = useTransactionTableHeaders();
    const filters = useTransactionFilterItems();
    const { data, isLoading } = useGetTransactionsQuery(undefined);

    return (
        <Box height='100%' overflow='auto'>
            <Container sx={{padding: '24px 0'}}>

                <DataTable
                    data={data ?? []}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    localPagination
                    filterKeys={['coach_name', 'customer_name', 'paid_at']}
                    filter={hasTeam ? {
                        filterBar: {
                            filterItems: filters,
                            columns: [],
                            showVisibility: false
                        }
                    } : undefined}
                    noDataMessage={<NoTransactions message={t('components.dataTable.noData.noTransactions')} />}
                    noDataFoundMessage={<NoTransactions />}
                    isLoading={isLoading}
                    rowClick={() => {}}
                    />

            </Container>
        </Box>
    );
};

export default TransactionsTab;