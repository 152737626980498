import { t } from "i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { useGetPayoutsQuery } from "@/repositories/payout";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { selectStorefrontCurrency } from "@/repositories/storefront";
import { useGetPerformanceQuery } from "@/repositories/business_performance";

// Components
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import StatsCard from "./performance/stats_card";
import NoPayouts from "./performance/no_payouts";
import BarChart from "@/components/charts/bar_chart";
import BalanceCard from "./performance/balance_card";
import SecondaryToolbar from "@/components/secondary_toolbar";
import usePayoutTableHeaders from "./performance/payout_headers";


const PerformancePage: FC = () => {

    const navigate = useNavigate();
    const headers = usePayoutTableHeaders();
    const [period, setPeriod] = useState('month');
    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const accountCurrency = useSelector((state) => selectStorefrontCurrency(state, undefined));
    const {data: performanceData, isLoading: isPerformanceLoading} = useGetPerformanceQuery(period);
    const {data: payouts, isLoading: isPayoutsLoading} = useGetPayoutsQuery();
    const currencySymbol = getCurrencySymbol(accountCurrency?.toUpperCase());

    // Guard againt non-owners when in team
    useEffect(() => {
        if (hasTeam && !isTeamOwner) {
            navigate('/business/payments/products');
        }
    }, [hasTeam, isTeamOwner]);

    const legends = {
        LY: performanceData?.ly_revenue ?? [],
        YTD: performanceData?.ytd_revenue ?? []
    }
    const chartData = Object.entries(legends).map(([key, value]) => value.map((item, index) => ({
        id: index+1,
        group: key,
        date: item.date,
        net_revenue: item.net_revenue
    }))).flat();

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.business.performance.title')}
                />

            <Box height='100%' overflow='auto'>
                <Container sx={{padding: '24px 0'}}>

                    <Box display="flex" gap="24px">

                        {/* Stats Card */}
                        <StatsCard
                            performanceData={performanceData}
                            period={period}
                            isPerformanceLoading={isPerformanceLoading}
                            onSetPeriod={setPeriod}
                            />

                        {/* Balance Card */}
                        <BalanceCard
                            available_balance={performanceData?.available_balance ?? 0}
                            pending_balance={performanceData?.pending_balance ?? 0}
                            isLoading={isPerformanceLoading}
                            />

                    </Box>

                    {/* Spacer */}
                    <Box height="24px" />

                    {/* Revenue Chart */}
                    <Box display="flex">
                        {chartData.length > 0 && <BarChart
                            title={t('components.charts.titles.revenueByMonth')}
                            data={chartData}
                            metric={'net_revenue'}
                            yLabel={t('components.charts.labels.revenue')}
                            xLabel={t('components.charts.labels.month')}
                            yPrefix={currencySymbol}
                            />}
                    </Box>

                    {/* Spacer */}
                    <Box height="24px" />

                    <DataTable
                        data={payouts ?? []}
                        dataKey="id"
                        columns={headers} 
                        header={{
                            title: t('components.dataTable.titles.payouts'),
                        }}
                        hideFilter
                        localPagination
                        filterKeys={['name']}
                        noDataMessage={<NoPayouts />}
                        isLoading={isPayoutsLoading}
                        />
                    
                </Container>
            </Box>
            
        </Box>
    );

}

export default PerformancePage;