import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Helpers
import { useMobile } from '@/_lib/is_mobile';

// Styles
import logo from '@/assets/logos/1fit_logo_mini_teal.png';

// Components
import { Box } from '@mui/material';


export default function AuthLayout() {

    const { t } = useTranslation();
    const isMobile = useMobile();

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Box 
                display="flex" 
                width="100%" 
                padding="10px 0" 
                alignItems="center" 
                justifyContent="center" 
                color="var(--nav-text-primary)" 
                bgcolor="var(--nav-background)" 
                borderBottom="solid 1px white"
                >
                {!isMobile ? 
                    <p className="helper-text-01" style={{padding: '0 16px'}}>{t('layouts.auth.banner')}</p> :
                    <p className="helper-text-01" style={{padding: '0 16px'}}>{t('layouts.auth.trialBanner')}</p>}
            </Box>

            <Box 
                display="grid" 
                gridTemplateColumns="1fr 1fr"
                width="100%"
                height="100vh"
                sx={{'@media (max-width: 768px)': {gridTemplateColumns: '1fr'}}}
                >

                {/* Login Form */}
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center" 
                    justifyContent="center" 
                    height="100%"
                    >
                    <Outlet />
                </Box>

                {/* Brand Column */}
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center" 
                    justifyContent="center" 
                    height="100%"
                    bgcolor="var(--nav-background)"
                    sx={{'@media (max-width: 768px)': {display: 'none'}}}
                    >
                    <img src={logo} alt="1fit-logo" height={100}/>
                    <Box height={50} />
                    <h5 className="heading-05 text-on-color">{t('layouts.auth.brandText')}</h5>
                </Box>

            </Box>

        </Box>
    )
}