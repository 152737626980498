import { t } from "i18next";
import { useSelector } from "react-redux";
import { FC, ReactNode, useEffect, useState } from "react";

// Helpers
import { useFirstRender } from "@/_helpers/hooks";

// Services and interfaces
import { selectStorefrontId, useLazyManageAccountQuery } from "@/repositories/storefront";

// Styles
import { Warning } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import {loadConnectAndInitialize, StripeConnectInstance} from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectAccountManagement } from "@stripe/react-connect-js";


interface _ManageAccountProps {
    open: boolean;
    onClose: (() => void);
}

const ManageAccountModal: FC<_ManageAccountProps> = ({
    open,
    onClose,
}) => {

    const isFirstRender = useFirstRender();
    const [hasError, setHasError] = useState(false);
    const [manageAccount] = useLazyManageAccountQuery();
    const storefrontId = useSelector((state) => selectStorefrontId(state, undefined));
    const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(null);


    useEffect(() => {
        if (isFirstRender.current && storefrontId) {
            const fetchAndInitialize = async () => {
                const fetchClientSecret = async (): Promise<string> => {
                    try {
                        const response = await manageAccount(storefrontId).unwrap();
                        if (!response) {
                            throw new Error("Client secret is missing");
                        }
                        return response.client_secret;
                    } catch (error) {
                        setHasError(true);
                        throw new Error("Failed to fetch client secret");
                    }
                };
                    
                try {
                    const instance = loadConnectAndInitialize({
                        publishableKey: import.meta.env.VITE_STRIPE_KEY,
                        fetchClientSecret,
                        appearance: {
                            variables: {
                              colorPrimary: '#1D212D',
                            },
                          },
                    });
                    setStripeConnectInstance(instance);
                } catch (error) {
                    setHasError(true);
                }
            };    
            fetchAndInitialize();
        }
      }, [isFirstRender]);

    if (hasError) {
        return (
            <_Modal open={open} onClose={onClose}>
                <_ErrorMessage />
            </_Modal>
        )
    }

    if (stripeConnectInstance === null) {
        return null;
    }

    return (
        <_Modal open={open} onClose={onClose}>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectAccountManagement />
            </ConnectComponentsProvider>
        </_Modal>
    )
}

export default ManageAccountModal;

interface _ModalProps {
    open: boolean;
    onClose: (() => void);
    children: ReactNode;
}

const _Modal: FC<_ModalProps> = ({
    open,
    onClose,
    children,
}) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.manageAccount.title')}
            children={children}
            sx={{'& .MuiPaper-root': {minWidth: '800px', background: 'var(--background)'}}}
            />
    )
}

const _ErrorMessage: FC = () => {

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" rowGap="12px" padding="48px">
            <Warning size="32" />
            <span className="heading-05">{t('modals.accountManagementError.title')}</span>
            <span className="body-02">{t('modals.accountManagementError.text')}</span>
        </Box>
    )
}