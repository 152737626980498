import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Helpers
import showToast from '@/_lib/toast';
import { useFirstRender } from '@/_helpers/hooks';

// Services
import { ApiError } from "@/interfaces/api/error";
import { useResendVerificationEmailMutation, useVerifyEmailMutation } from "@/repositories/auth";

// Components
import { Box, CircularProgress } from '@mui/material';


const VerificationPage = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const isFirstRender = useFirstRender();
    
    const [verifyEmail] = useVerifyEmailMutation();
    const [resendVerification] = useResendVerificationEmailMutation();

    const [searchParams] = useSearchParams();
    const url = searchParams.get("verifyLink");
    const id: string|undefined = url?.split("?")[0].split("/").pop();

    useEffect(() => {
        if (isFirstRender.current) {
            if (url == null) {
                navigate('/auth/login');
                showToast({type: 'error', title: t('notifications.verification.invalidTitle'), message: t('notifications.verification.invalidSubtitle')});
                return;
            }

            verifyEmail(url).unwrap().then(() => {
                navigate('/auth/login');
                showToast({type: 'success', title: t('notifications.verification.successTitle'), message: t('notifications.verification.successSubtitle')});
            }).catch((error: ApiError) => {
                if (error.type == 'verification_already_verified') {
                    navigate('/auth/login');
                    showToast({type: 'info', apiError: error.type});
                } else if (error.type == 'verification_url_expired') {
                    navigate('/auth/login');
                    showToast({type: 'error', apiError: error.type, action: {label: t('buttons.resendVerification', { seconds: ``}), onClick: submit}});
                } else {
                    navigate('/auth/login');
                    showToast({type: 'error', apiError: error.type});
                }
            });
        }
      }, [isFirstRender]);

    const submit = async () => {
        if (!id) return;
        await resendVerification(parseInt(id));
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
    );
}

export default VerificationPage;