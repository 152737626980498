import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import {  useSearchParams } from "react-router-dom";

// Services and interfaces
import { selectPaymentsCancelled } from "@/store/subscription";
import { useGetStorefrontQuery } from "@/repositories/storefront";

// Components
import Link from "@/components/link";
import { Box, Container } from "@mui/material";
import StorefrontProfile from "./storefront/profile";
import StorefrontSettings from "./storefront/settings";
import StripeProvider from "./storefront/stripe_provider";
import StripeOAuthConnector from "./storefront/stripe_oauth_connector";
import StripeProviderConnected from "./storefront/stripe_provider_connected";
import AccountRequirementRequired from "./storefront/account_requirement_required";


const PayPage: FC = () => {

    const [searchParams] = useSearchParams(); 
    const error = searchParams.get('error');
    const provider = searchParams.get('provider');
    const {data: storefront } = useGetStorefrontQuery();
    const paymentsCancelled = useSelector(selectPaymentsCancelled)

    const loadStripe = storefront && provider === 'stripe' && !storefront.provider && !error;

    return (
        <Container sx={{py: '24px'}}>

            {loadStripe && <StripeOAuthConnector storefront_id={storefront.id} />}

            <Box display="flex" flexDirection="column" justifyContent="flex-start" rowGap="24px" paddingBottom="40px">

                {storefront?.requirements && Object.entries(storefront.requirements)
                    .filter(([requirement, value]) => requirement != 'errors' && requirement != 'current_deadline' && value != 'eventually_due')
                    .map(([key, value]) => <AccountRequirementRequired
                        key={key}
                        requirement={key}
                        status={value} 
                        current_deadline={storefront.requirements.current_deadline} 
                        />)}

                {!paymentsCancelled && !storefront?.provider && storefront && <StripeProvider 
                    storefront_id={storefront.id} 
                    can_reconnect={storefront.can_reconnect_stripe}
                    />}

                <StorefrontProfile />
                <StorefrontSettings />
                {!paymentsCancelled && storefront && storefront.provider && <StripeProviderConnected storefront={storefront} />}

                {/* Link to payments and billing */}
                <Box display="flex">
                    <span className="body-02-compact" style={{marginRight: '6px'}}>{t('pages.settings.storefront.billingLink')}</span>
                    <Link
                        label={t('components.links.paymentsAndBilling')}
                        to="/settings/billing"
                        />
                </Box>

            </Box>
        </Container>
    )
}

export default PayPage;