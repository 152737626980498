import { FC } from "react";
import { t } from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";
import { roundNumber } from "@/_helpers/number_functions";
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useCreatePayoutMutation } from "@/repositories/payout";
import { IPayoutForm, payoutFormSchema } from "@/interfaces/payout/payout_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import PayoutForm from "./payout_form";
import Modal from "@/components/modal/modal";


interface _PayoutModalProps {
    open: boolean;
    onClose: () => void;
    available_balance: number;
    pending_balance: number;
    currency: string;
}

const PayoutModal: FC<_PayoutModalProps> = ({
    open,
    onClose,
    available_balance,
    pending_balance,
    currency
}) => {

    const currencySymbol = getCurrencySymbol(currency?.toUpperCase());
    const balance_limit = roundNumber(available_balance + (pending_balance < 0 ? pending_balance : 0), 2);
    const [createPayout, { isLoading: isCreatingPayout }] = useCreatePayoutMutation();

    const formMethods = useForm<IPayoutForm>({
        resolver: zodResolver(payoutFormSchema(balance_limit, currencySymbol)),
        mode: 'onBlur',
        defaultValues: {
            type: 'full',
            amount: balance_limit
        }
    });

    const handleFullPayout = (value: 'full'|'partial') => {
        if (value === 'full') {
            formMethods.setValue('amount', balance_limit);
            formMethods.clearErrors('amount');
        }
    }

    const handlePayoutTransaction = (data: IPayoutForm) => {
        createPayout(data).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.payout.created.title'),
                message: t('notifications.payout.created.message', {
                    context: `${data.type == 'full'}`,
                    currency: currencySymbol,
                    amount: data.amount
                })
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t('modals.createPayout.title')}
            text={t('modals.createPayout.text', {
                symbol: currencySymbol,
                amount: balance_limit,
                iso_code: currency?.toUpperCase()
            })}
            children={
                <FormProvider {...formMethods}>
                    <PayoutForm balance_limit={balance_limit} currencySymbol={currencySymbol} onPayoutChange={handleFullPayout} />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.payout'),
                icon: <ArrowRight />,
                loading: isCreatingPayout,
                onClick: formMethods.handleSubmit(handlePayoutTransaction)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isCreatingPayout,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default PayoutModal;